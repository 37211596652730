import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { BoxButton } from '@instech/components';
import { ErrorTypes } from './ErrorTypes';
import { ErrorBirds } from './ErrorBirds';
import { ErrorWave } from './ErrorWave';
import { ErrorImage } from './ErrorImage';

const Layout = styled.div`
  min-height: 100vh;
  background-color: #BFCEDB;
`;

const ImageContainer = styled.div`
  position: relative;
  background-color: #D9E3EC;
  height: 612px;  

  @media screen and (max-width: 600px) {
    height: 354px;
  }
`;

const TextContainer = styled.div`
  max-width: 640px;
  margin: 0px auto;
  padding: 40px 8px 0px;

  @media screen and (max-width: 600px) {
    padding-top: 32px;
  }
`;

const ButtonContainer = styled.div`
  max-width: 412px;
  margin: 0px auto;
  padding: 64px 8px 80px;
`;

const ErrorTitle = styled.h1`
  margin: 0px auto 28px;
  text-align: center;
  font-size: 62px;
  line-height: 77px;
  font-weight: bold;
  color: #003C71;

  @media screen and (max-width: 600px) {
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 16px;
  }
`;

const ErrorMessage = styled.p`
  margin: 0px auto;
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  color: #212121;

  @media screen and (max-width: 600px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

const HeightSpace = styled.div`
  padding: 12px;
  width: 100%;
`;

export const ErrorView = ({ type, title, message, reset }) => {
  const history = useHistory();
  const redirectHome = () => {
    history.push('/');
    reset();
  };
  const reloadPage = () => {
    window.location.reload(false);
  };

  return (
    <Layout>
      <ImageContainer>
        <ErrorBirds />
        <ErrorImage error={type} />
        <ErrorWave />
      </ImageContainer>
      <TextContainer>
        <ErrorTitle>{title}</ErrorTitle>
        <ErrorMessage>{message}</ErrorMessage>
      </TextContainer>
      <ButtonContainer>
        <BoxButton width="100%" onClick={redirectHome}>
          Go to Home page
        </BoxButton>
        <HeightSpace />
        <BoxButton width="100%" onClick={reloadPage}>
          Reload page
        </BoxButton>
      </ButtonContainer>
    </Layout>
  );
};

ErrorView.propTypes = {
  type: PropTypes.oneOf(Object.values(ErrorTypes)),
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired
};
