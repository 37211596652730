/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { childrenPropType } from '@/prop-types/custom-prop-types';
import { Route } from 'react-router-dom';
import { useAccount } from '../../providers/authenticationProvider';

const AuthenticationHandler = ({ children }) => {
  const { isAuthenticated, login } = useAccount();
  if (isAuthenticated !== true) {
    login();
  }

  return children;
};

AuthenticationHandler.propTypes = {
  children: childrenPropType
};

export const ProtectedRoute = ({ children, ...rest }) => (
  <Route {...rest}>
    <AuthenticationHandler>
      {children}
    </AuthenticationHandler>
  </Route>
);

ProtectedRoute.propTypes = {
  children: childrenPropType
};

export default ProtectedRoute;
