import {
  EmergencyReportDraft, UserInfo, Vessel, Insurance,
  Category, SubCategory, Position, MapSettings, Comment
} from '@/types';
import useSWR from 'swr';
import { useAccount } from '@/providers/authenticationProvider';
import { useReportId } from '@/hooks/useReportId';
import { postAsync, postJsonAsync } from './client';
import { usePosition } from './PositionService';

const urlWithReportId = (reportId: string) => `/emergency/${reportId}`;

interface CreateDraftRequest {
  vessel: Vessel;
  userInfo: UserInfo;
}
export const createDraftAsync = async (draft: CreateDraftRequest) => {
  const url = '/emergency/draft';
  const result = await postJsonAsync<EmergencyReportDraft>(url, draft);
  return result;
};

export const distributeReportAsync = async (reportId: string) => {
  const url = `${urlWithReportId(reportId)}/distribute`;
  const result = await postAsync<EmergencyReportDraft>(url);
  return result;
};

const addReportPartAsync = async (reportId: string, part: string, request: any) =>
  postJsonAsync<EmergencyReportDraft>(`${urlWithReportId(reportId)}/${part}`, request);

interface AddInsuranceRequest {
  insurances: Insurance[];
  selectedInsurance: string;
}
export const addInsuranceAsync = async (reportId: string, request: AddInsuranceRequest) =>
  addReportPartAsync(reportId, 'insurance', request);

interface AddCategoryRequest {
  category: Category;
}
export const addCategoryAsync = async (reportId: string, request: AddCategoryRequest) =>
  addReportPartAsync(reportId, 'category', request);

interface AddSubCategoryRequest {
  subCategory: SubCategory;
}
export const addSubCategoryAsync = async (reportId: string, request: AddSubCategoryRequest) =>
  addReportPartAsync(reportId, 'subCategory', request);

interface AddPositionRequest {
  position: Position;
  mapSettings: MapSettings;
}
export const addPositionAsync = async (reportId: string, request: AddPositionRequest) =>
  addReportPartAsync(reportId, 'position', request);

interface AddCommentRequest {
  comment: Comment;
}
export const addCommentAsync = async (reportId: string, request: AddCommentRequest) =>
  addReportPartAsync(reportId, 'comment', request);

export const useReport = () => {
  const reportId = useReportId();
  const { data: report, mutate } = useSWR<EmergencyReportDraft>(reportId ? urlWithReportId(reportId) : null);
  const { data: position } = usePosition(report?.vessel?.imoNumber);
  const { user } = useAccount();

  const revalidate = (data: EmergencyReportDraft) => mutate(data, false);

  const selectVessel = async (vessel: Vessel) => {
    if (!user) throw new Error('User not found');
    const id = await createDraftAsync({ vessel, userInfo: user })
      .then(x => x.id);
    return id;
  };

  const selectInsurance = (insurance: Insurance, insuranceList: Insurance[]) =>
    void addInsuranceAsync(reportId, { selectedInsurance: insurance.id, insurances: insuranceList })
      .then(revalidate);

  const selectCategory = (category: Category) =>
    void addCategoryAsync(reportId, { category })
      .then(revalidate);

  const selectSubCategory = (subCategory: SubCategory) =>
    void addSubCategoryAsync(reportId, { subCategory })
      .then(revalidate);

  const selectComment = async (comment: Comment) =>
    addCommentAsync(reportId, { comment })
      .then(revalidate);

  return { report,
    position,
    selectVessel,
    selectInsurance,
    selectCategory,
    selectSubCategory,
    selectComment };
};
