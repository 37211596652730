import React, {
  PropsWithChildren,
  useCallback, useEffect, useRef, useState
} from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  $getSelection,
  $isRangeSelection,
  $createParagraphNode,
  COMMAND_PRIORITY_CRITICAL
} from 'lexical';
import { $wrapNodes } from '@lexical/selection';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode,
} from '@lexical/list';
import { createPortal } from 'react-dom';
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode,
} from '@lexical/rich-text';
import useModal from '../../hooks/useModal';
import { InsertImageDialog } from '../ImagesPlugin/InsertImageDialog';
import './styles.css';

interface ToolbarProps {
  withImageLoader?: boolean;
}
const LowPriority = 1;

const supportedBlockTypes = new Set(['paragraph', 'h1', 'h2', 'h3']);

const blockTypeToBlockName: { [ key: string ]: string } = {
  h1: 'Large Heading',
  h2: 'Middle Heading',
  h3: 'Small Heading',
  paragraph: 'Paragraph',
};

function Divider() {
  return <div className="divider editor-toolbar-divider" />;
}

const ToolbarText = ({ children }: PropsWithChildren<{children?: string}>) =>
  <span className="toolbar-item editor-toolbar-text">{children}</span>;

interface BlockOptionsDropdownListProps {
  editor: any;
  blockType: string;
  toolbarRef: any;
  setShowBlockOptionsDropDown: (showBlockOptionsDropDown: boolean) => void;
}

function BlockOptionsDropdownList({
  editor,
  blockType,
  toolbarRef,
  setShowBlockOptionsDropDown,
}: BlockOptionsDropdownListProps) {
  const dropDownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const toolbar = toolbarRef.current;
    const dropDown = dropDownRef.current;

    if (toolbar !== null && dropDown !== null) {
      const { top, left } = toolbar.getBoundingClientRect();
      dropDown.style.top = `${top + 40}px`;
      dropDown.style.left = `${left}px`;
    }
  }, [dropDownRef, toolbarRef]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const dropDown = dropDownRef.current;
    const toolbar = toolbarRef.current;

    if (dropDown !== null && toolbar !== null) {
      const handle = (event: MouseEvent) => {
        const target = event.target as HTMLElement;

        if (!dropDown.contains(target) && !toolbar.contains(target)) {
          setShowBlockOptionsDropDown(false);
        }
      };
      document.addEventListener('click', handle);

      return () => {
        document.removeEventListener('click', handle);
      };
    }
  }, [dropDownRef, setShowBlockOptionsDropDown, toolbarRef]);

  const formatParagraph = () => {
    if (blockType !== 'paragraph') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatLargeHeading = () => {
    if (blockType !== 'h1') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode('h1'));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatMiddleHeading = () => {
    if (blockType !== 'h2') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode('h2'));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatSmallHeading = () => {
    if (blockType !== 'h3') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode('h3'));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  return (
    <div className="dropdown" ref={dropDownRef}>
      <button className="item" type="button" onClick={formatParagraph}>
        <span className="icon paragraph" />
        <span className="text">Paragraph</span>
        {blockType === 'paragraph' && <span className="active" />}
      </button>
      <button className="item" type="button" onClick={formatLargeHeading}>
        <span className="icon large-heading" />
        <span className="text">Large Heading</span>
        {blockType === 'h1' && <span className="active" />}
      </button>
      <button className="item" type="button" onClick={formatMiddleHeading}>
        <span className="icon middle-heading" />
        <span className="text">Middle Heading</span>
        {blockType === 'h2' && <span className="active" />}
      </button>
      <button className="item" type="button" onClick={formatSmallHeading}>
        <span className="icon small-heading" />
        <span className="text">Small Heading</span>
        {blockType === 'h3' && <span className="active" />}
      </button>
    </div>
  );
}

export const ToolbarPlugin: React.FC<ToolbarProps> = ({ withImageLoader }) => {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [canUndo, setCanUndo] = useState<boolean>(false);
  const [canRedo, setCanRedo] = useState<boolean>(false);
  const [activeEditor, setActiveEditor] = useState(editor);
  const [modal, showModal] = useModal();

  const [blockType, setBlockType] = useState<string>('paragraph');
  const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] =
    useState(false);
  const [isBold, setIsBold] = useState<boolean>(false);
  const [isItalic, setIsItalic] = useState<boolean>(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
        }
      }
      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
    }
  }, [editor]);

  useEffect(() => editor.registerCommand(
    SELECTION_CHANGE_COMMAND,
    (_payload, newEditor) => {
      updateToolbar();
      setActiveEditor(newEditor);
      return false;
    },
    COMMAND_PRIORITY_CRITICAL
  ), [editor, updateToolbar]);

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateToolbar();
          });
        }),
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          (_payload, newEditor) => {
            updateToolbar();
            return false;
          },
          LowPriority
        ),
        editor.registerCommand(
          CAN_UNDO_COMMAND,
          payload => {
            setCanUndo(payload);
            return false;
          },
          LowPriority
        ),
        editor.registerCommand(
          CAN_REDO_COMMAND,
          payload => {
            setCanRedo(payload);
            return false;
          },
          LowPriority
        )
      ),
    [editor, updateToolbar]
  );

  const formatBulletList = () => {
    if (blockType !== 'ul') {
      // @ts-ignore
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
    } else {
      // @ts-ignore
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== 'ol') {
      // @ts-ignore
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
    } else {
      // @ts-ignore
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
  };

  const formatQuote = () => {
    if (blockType !== 'quote') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createQuoteNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const Dialog = useCallback(({ onClose }) => <InsertImageDialog activeEditor={activeEditor} onClose={onClose} />, [activeEditor]);

  const onInsertImageClick = () => {
    showModal('Insert Image', onClose => <Dialog onClose={onClose} />);
  };
  return (
    <div className="toolbar" ref={toolbarRef}>
      <button
        disabled={!canUndo}
        type="button"
        onClick={() => {
          // @ts-ignore
          editor.dispatchCommand(UNDO_COMMAND);
        }}
        className="toolbar-item spaced"
        aria-label="Undo"
      >
        <i className="format undo" />
      </button>
      <button
        disabled={!canRedo}
        type="button"
        onClick={() => {
          // @ts-ignore
          editor.dispatchCommand(REDO_COMMAND);
        }}
        className="toolbar-item"
        aria-label="Redo"
      >
        <i className="format redo" />
      </button>
      <Divider />
      <button
        className="toolbar-item block-controls"
        type="button"
        onClick={() => setShowBlockOptionsDropDown(!showBlockOptionsDropDown)}
        aria-label="Formatting Options"
      >
        {supportedBlockTypes.has(blockType) ? (
          <>
            <span className={`icon block-type ${blockType}`} />
            <ToolbarText>{blockTypeToBlockName[blockType]}</ToolbarText>
          </>
        ) : (
          <>
            <span className="icon block-type paragraph" />
            <ToolbarText>{blockTypeToBlockName.paragraph}</ToolbarText>
          </>
        )}
        <i className="chevron-down" />
      </button>
      {showBlockOptionsDropDown &&
        createPortal(
          <BlockOptionsDropdownList
            editor={editor}
            blockType={blockType}
            toolbarRef={toolbarRef}
            setShowBlockOptionsDropDown={setShowBlockOptionsDropDown}
          />,
          document.body
        )}
      <Divider />
      <button
        className={`toolbar-item spaced ${
          blockType === 'ul' ? 'active' : ''
        }`}
        type="button"
        onClick={formatBulletList}
      >
        <i className="icon bullet-list" />
        {blockType === 'ul' && <span className="active" />}
      </button>
      <button
        className={`toolbar-item spaced ${
          blockType === 'ol' ? 'active' : ''
        }`}
        type="button"
        onClick={formatNumberedList}
      >
        <i className="icon numbered-list" />
        {blockType === 'ol' && <span className="active" />}
      </button>
      <button
        className={`toolbar-item spaced ${
          blockType === 'quote' ? 'active' : ''
        }`}
        type="button"
        onClick={formatQuote}
      >
        <i className="icon quote" />
      </button>

      <Divider />
      <button
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
          }}
          type="button"
          className={`toolbar-item spaced ${isBold ? 'active' : ''}`}
          aria-label="Format Bold"
        >
        <i className="format bold" />
      </button>
      <button
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
          }}
          className={`toolbar-item spaced ${isItalic ? 'active' : ''}`}
          type="button"
          aria-label="Format Italics"
        >
        <i className="format italic" />
      </button>

      <Divider />
      {withImageLoader && (
        <button
            onClick={onInsertImageClick}
            className="toolbar-item spaced"
            aria-label="Insert Image"
            title="Insert Image"
            type="button"
          >
          <i className="icon image" />
        </button>
      )}
      {modal}
    </div>
  );
};
