import React from 'react';
import styled from 'styled-components';
import { Update as UpdatesPageWidget } from 'insify-remote-component-loader';
import { useAccount } from '@/providers/authenticationProvider';
import { useHistory } from 'react-router-dom';
import { Page } from '@/components/layout/Page';

const ContentArea = styled.div`
  margin: 0 auto;
  background: ${props => props.theme.whiteBlue};
`;

export const UpdatesPage = () => {
  const account = useAccount();
  const history = useHistory();

  return (
    <Page>
      <ContentArea>
        <UpdatesPageWidget account={account} history={history} portal={{ name: 'emergency', displayName: 'Emergency' }} />
      </ContentArea>
    </Page>
  );
};
