import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useInsurances } from '@/services/InsuranceService';
import useWindowSize from '@/services/WindowSize';
import { formatNumber } from '@/utils/number';
import { BoxButton } from '@instech/components';
import { CheckThin } from '@instech/icons';
import {
  Insurance, LabelFieldProps, Vessel
} from '@/types';
import { useReport } from '@/services/EmergencyReportService';
import { useReportId } from '@/hooks/useReportId';
import { PageNavigator } from '../shared/PageNavigator';
import VesselInformation from '../components/vessel/VesselDetail';

const InsuranceArea = styled.div`
  min-height: calc(100vh - 60px);
  background-color: ${props => props.theme.whiteBlue};
  background-size: cover;
  width: 100%;
  margin: 0 auto;
`;

const Content = styled.div`
  min-height: calc(100vh - 60px);
  background-color: ${props => props.theme.white};
  background-size: cover;
  padding-top: 10px 0px 0px 0px;
  margin: 0 auto;
  max-width: 1200px;
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  grid-auto-flow: column;
  border-bottom: 1px solid blue;
  padding: 5px;
  font-size: 20px;
  line-height: 28px;
  align-items: center;
  margin-left: 10px;
`;

const HeaderField = styled.span`
  font-weight: normal;
`;

const CoverPane = styled.div`
  background: ${props => props.theme.whiteBlue};
  margin: 10px;
`;

const ItemArea = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 40px;
  padding-bottom: 30px;
`;

const Item = styled.div`
  background-color: ${props => props.theme.whiteBlue};
  margin-left: 20px;
  margin-top: 5px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  align-items: center;
  color: #222;
  font-weight: 300;
`;

const ItemDetails = styled.div`
  width: 100%;
`;

const ItemLabel = styled.span`
  font-weight: bold;
`;

const ItemField = styled.span`
  font-weight: normal;
`;

const MobileFooter = styled.div`
  width: 100%;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

interface HeaderAreaProps {
  cover: Insurance;
  handleSelect: (cover: Insurance) => void;
}
const HeaderArea = ({ cover, handleSelect }: HeaderAreaProps) => {
  const size = useWindowSize();

  return (
    <Header>
      <HeaderField>{cover.interest}</HeaderField>
      <HeaderField>{cover.currency}</HeaderField>
      <HeaderField>{formatNumber(cover.sumInsured)}</HeaderField>
      {size && !size.isMobile && (
        <BoxButton
          startIcon={<CheckThin />}
          onClick={() => handleSelect(cover)}
          width="120px"
          height="48px"
        >
          Select
        </BoxButton>
      )}
    </Header>
  );
};

const InsuranceDetail = ({ label, field }: LabelFieldProps) => (
  <Item>
    <ItemDetails>
      <ItemLabel>{label}</ItemLabel>
      <ItemField>{field}</ItemField>
    </ItemDetails>
  </Item>
);

interface InsuranceLoaderProps {
  children: (insurances: Insurance[], vessel: Vessel) => ReactElement;
}
const InsuranceLoader = ({ children }: InsuranceLoaderProps) => {
  const { report } = useReport();
  const { data: insurances } = useInsurances(report?.vessel?.imoNumber);
  if (!report || !insurances) return null;
  return children(insurances, report.vessel);
};

interface InsuranceContainerProps {
  insurances: Insurance[];
  vessel: Vessel;
}
const InsuranceContainer = ({ insurances, vessel }: InsuranceContainerProps) => {
  const history = useHistory();
  const size = useWindowSize();
  const reportId = useReportId();
  const { selectInsurance } = useReport();

  const handleSelect = (cover: Insurance) => {
    selectInsurance(cover, insurances);
    history.push(`/${reportId}/category`);
  };

  return (
    <InsuranceArea>
      <Content>
        <PageNavigator
          section={vessel.imoNumber}
          title={vessel.vesselName}
          toPagePrevious="/vessel"
          toPageNext={`/${reportId}/category`}
        />
        <VesselInformation vessel={vessel} />
        {insurances.map((cover, i) => (
          <CoverPane key={cover.id}>
            <HeaderArea cover={cover} handleSelect={handleSelect} />
            <ItemArea>
              <InsuranceDetail label="Broker: " field={cover.broker} />
              <InsuranceDetail
                  label="Insurance year: "
                  field={cover.insuranceYear}
                />
              <InsuranceDetail
                  label="Claimslead: "
                  field={cover.claimsLeader}
                />
              <InsuranceDetail
                  label="Our pct: "
                  field={formatNumber(cover.ourShare)}
                />
              <InsuranceDetail
                  label="Start date: "
                  field={cover.insurancePeriodFrom ?? '-'}
                />
              <InsuranceDetail label="Fleet name: " field={cover.fleetName} />
              <InsuranceDetail label="Covernote: " field={cover.coverNote} />
              <InsuranceDetail
                  label="End date: "
                  field={cover.insurancePeriodTo ?? '-'}
                />
              <InsuranceDetail
                  label="Claim section: "
                  field={cover.claimSection}
                />
            </ItemArea>
            {size?.isMobile && (
            <MobileFooter>
              <BoxButton
                    startIcon={<CheckThin />}
                    onClick={() => handleSelect(cover)}
                    width="120px"
                    height="48px"
                  >
                Select
              </BoxButton>
            </MobileFooter>
            )}
          </CoverPane>
        ))}
      </Content>
    </InsuranceArea>
  );
};

export const InsurancePage = () => (
  <InsuranceLoader>
    {(insurances, vessel) => (
      <InsuranceContainer vessel={vessel} insurances={insurances} />
    )}
  </InsuranceLoader>
);

export default InsurancePage;
