import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { LeftArrow, RightArrow } from '@instech/icons';
import { MobileButton } from './MobileButton';
import { childrenPropType } from '../../prop-types/custom-prop-types';

export const NextMobileButton = ({ onClick, label = 'Next' }) => (
  <MobileButton
    endIcon={<RightArrow />}
    width="125px"
    height="50px"
    fontSize="18px"
    boldFont
    onClick={onClick}
  >
    {label}
  </MobileButton>
);

NextMobileButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
};

export const NextMobileNavButton = ({
  onClick,
  to,
  label = 'Next',
  disabled,
  showIcon = true,
}) => (
  <Link to={to}>
    <MobileButton
      inverted
      endIcon={showIcon && <RightArrow />}
      width="125px"
      height="50px"
      margin-left="3px"
      fontSize="14px"
      boldFont
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </MobileButton>
  </Link>
);

NextMobileNavButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  showIcon: PropTypes.bool,
  to: PropTypes.string.isRequired,
};

export const PreviousMobileButton = ({ onClick }) => (
  <MobileButton
    inverted
    startIcon={<LeftArrow />}
    width="125px"
    height="50px"
    fontSize="18px"
    boldFont={false}
    onClick={onClick}
  >
    Previous
  </MobileButton>
);

PreviousMobileButton.propTypes = {
  onClick: PropTypes.func,
};

export const PreviousMobileNavButton = ({ onClick, to }) => (
  <Link to={to}>
    <MobileButton
      inverted
      startIcon={<LeftArrow />}
      width="125px"
      height="50px"
      margin-right="3px"
      fontSize="14px"
      boldFont
      onClick={onClick}
    >
      Previous
    </MobileButton>
  </Link>
);

PreviousMobileNavButton.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.string.isRequired,
};

// MobileButton wrapped in a React-Router Link
export const MobileNavBox = ({
  children,
  inverted,
  startIcon: StartIcon,
  endIcon: EndIcon,
  width,
  height,
  to,
  onClick,
}) => (
  <Link to={to}>
    <MobileButton
      inverted={inverted}
      startIcon={StartIcon && <StartIcon />}
      endIcon={EndIcon && <EndIcon />}
      width={width}
      height={height}
      onClick={onClick}
    >
      {children}
    </MobileButton>
  </Link>
);

MobileNavBox.propTypes = {
  children: childrenPropType,
  inverted: PropTypes.bool,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  width: PropTypes.string,
  height: PropTypes.string,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
