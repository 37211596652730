import React, {
  useState, useCallback, useRef
} from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import { useEmergencyConfiguration } from '@/services/ConfigurationService';
import styled from 'styled-components';
import { Position } from '@/types';
import { google } from 'google-maps';
import { useMapSettingsContext } from '@/providers/MapProvider';

const Container = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.whiteBlue};
  background-size: cover;
  margin: 0 auto;
`;

const mapContainerStyle = { height: '100%', width: '100%' };
const options = { disableDefaultUI: true, zoomControl: true };

interface InfoWindowContainerProps {
  selected: Position;
  setSelected: (position: Position | null) => void;
}
const InfoWindowContainer = ({ selected, setSelected }: InfoWindowContainerProps) => (
  <InfoWindow
      position={{ lat: selected.latitude + 1, lng: selected.longitude }}
      onCloseClick={() => setSelected(null)}
    >
    <div>
      <h2>Latest Position</h2>
      <p>
        Latitude:
        {' '}
        {selected.latitudeDegree}
        {' '}
        Longitude:
        {' '}
        {selected.longitudeDegree}
      </p>
      <p>
        Registered
        {selected.date}
      </p>
    </div>
  </InfoWindow>
);

interface MapContainerProps { position: Position; }
export const MapContainer = ({ position }: MapContainerProps) => {
  const { data } = useEmergencyConfiguration();
  const { mapSettings, setMapSettings } = useMapSettingsContext();
  const center = {
    lat: Object.keys(position).length !== 0 ? position.latitude : 0,
    lng: Object.keys(position).length !== 0 ? position.longitude : 0,
  };
  const [markers] = useState<Position[]>([position]);
  const [selected, setSelected] = useState<Position | null>(null);
  const zoom = mapSettings?.zoom ?? 5;

  const mapRef = useRef<google.maps.Map>();

  const onMapLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);

  const onZoomChanged = useCallback(() => {
    if (mapRef?.current) setMapSettings({ zoom: mapRef.current.getZoom() ?? 0 });
  }, [setMapSettings]);
  return (
    <Container>
      {data && Object.keys(position).length !== 0 && (
        <LoadScript googleMapsApiKey={data.mapKey}>
          <GoogleMap
            id="map"
            mapContainerStyle={mapContainerStyle}
            zoom={zoom}
            center={center}
            options={options}
            onLoad={onMapLoad}
            onZoomChanged={onZoomChanged}
          >
            {markers.map(marker => (
              <Marker
                key={`${marker.latitude}-${marker.longitude}`}
                position={{ lat: marker.latitude, lng: marker.longitude }}
                onClick={() => {
                  setSelected(marker);
                }}
              />
            ))}

            {selected ? (
              <InfoWindowContainer
                selected={selected}
                setSelected={setSelected}
              />
            ) : null}
          </GoogleMap>
        </LoadScript>
      )}
    </Container>
  );
};
