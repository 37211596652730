import React from 'react';
import styled from 'styled-components';
import { InformationBox } from '@/shared/InformationBox';
import { formatNumber } from '@/utils/number';
import {
  Category, Insurance, LabelFieldProps, MapSettings, Position, SubCategory, Vessel
} from '@/types';
import { Loader, WarningNotification } from '@instech/components';
import { useMarkdownTransformer } from '@/shared/Form/RichTextEditor/hooks/useMarkdownTransformer';
import { useReport } from '@/services/EmergencyReportService';
import { useReportId } from '@/hooks/useReportId';
import { useMapSettings } from '@/providers/MapProvider';
import { RichTextView } from '../shared/Form/RichTextView';
import { Pane } from '../shared/Pane';
import { MapContainer } from '../components/map/MapContainer';
import { PageNavigator } from '../shared/PageNavigator';

const SummaryArea = styled.div`
  min-height: calc(100vh - 60px);
  background-color: ${props => props.theme.whiteBlue};
  background-size: cover;
  width: 100%;
  margin: 0 auto;
`;

const Content = styled.div`
  min-height: calc(100vh - 60px);
  background-color: ${props => props.theme.white};
  background-size: cover;
  padding-top: 10px 0px 0px 0px;
  margin: 0 auto;
  max-width: 1200px;
`;

const VesselArea = styled.div`
  background-color: ${props => props.theme.whiteBlue};
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  grid-gap: 10px;
  padding: 20px;

  @media screen and (min-width: ${props => props.theme.breakpoints.pxPhone}) {
    grid-template-columns: repeat(auto-fit, minmax(245px, 1fr));
  }
`;

const VesselItems = styled.div`
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  align-items: flex-start;
  color: ${props => props.theme.black};
  font-weight: 200;
`;

const VesselItem = styled.div`
  width: 100%;
`;

const VesselLabel = styled.div`
  font-weight: bold;
`;
const VesselField = styled.div`
  font-weight: normal;
`;

const PositionArea = styled.div``;

const PositionPane = styled.div`
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  align-items: flex-start;
  color: ${props => props.theme.black};
  font-weight: 200;

  @media screen and (min-width: ${props => props.theme.breakpoints.pxPhone}) {
    grid-template-columns: 2fr 1fr;
  }
`;

const PositionItems = styled.div`
  background-color: ${props => props.theme.whiteBlue};
  padding-left: 30px;
  padding: 10px 0px 0px 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0px;
  align-items: flex-start;
  color: ${props => props.theme.black};
  font-weight: 200;
`;

const PositionLabel = styled.div`
  font-weight: bold;
`;
const PositionField = styled.div`
  font-weight: normal;
`;

const CoverPane = styled.div`
  background: ${props => props.theme.whiteBlue};
  padding: 10px;
`;

const CoverItemArea = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 40px;
`;

const CoverItem = styled.div`
  background-color: ${props => props.theme.whiteBlue};
  margin-left: 20px;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0px;
  align-items: center;
  color: #222;
  font-weight: 300;
`;

const CoverItemDetails = styled.div`
  width: 100%;
`;

const CoverItemLabel = styled.span`
  font-weight: bold;
`;

const CoverItemField = styled.span`
  font-weight: normal;
`;

const MapPane = styled.div`
  height: 900px;
`;

const VesselDetail = ({ label, field }: LabelFieldProps) => (
  <VesselItems>
    <VesselItem>
      <VesselLabel>{label}</VesselLabel>
      <VesselField>{field}</VesselField>
    </VesselItem>
  </VesselItems>
);

const PositionDetail = ({ label, field }: LabelFieldProps) => (
  <PositionItems>
    <PositionLabel>{label}</PositionLabel>
    <PositionField>{field}</PositionField>
  </PositionItems>
);

const InsuranceDetail = ({ label, field }: LabelFieldProps) => (
  <CoverItem>
    <CoverItemDetails>
      <CoverItemLabel>{label}</CoverItemLabel>
      <CoverItemField>{field}</CoverItemField>
    </CoverItemDetails>
  </CoverItem>
);

const Categories = ({ category, subCategory }: { category?: Category; subCategory?: SubCategory; }) => (
  <Pane title="Category" padding="10px" margin="10px">
    {(category && subCategory) ? (
      <VesselArea>
        <VesselDetail label="Category" field={category.name} />
        <VesselDetail label="Sub Category" field={subCategory.name} />
      </VesselArea>
    ) : <WarningNotification size="small" headingText="No category selected" />}
  </Pane>
);

const VesselInformation = ({ vessel }: { vessel?: Vessel; }) => (
  <Pane title="Vessel Information" padding="10px" margin="10px">
    <VesselArea>
      <VesselDetail label="Vessel type" field={vessel?.vesselType} />
      <VesselDetail label="Flag" field={vessel?.flag} />
      <VesselDetail label="IMO number" field={vessel?.imoNumber} />
      <VesselDetail label="Built" field={vessel?.yearOfBuild} />
      <VesselDetail label="Width" field={vessel?.breadthMoulded} />
      <VesselDetail label="Deadweight" field={vessel?.deadWeightTonnage} />
      <VesselDetail
        label="Formula deadweight"
        field={vessel?.formulaDeadweightTonnage}
      />
      <VesselDetail label="Gross tonnage" field={vessel?.grossTonnage} />
      <VesselDetail label="Length" field={vessel?.lengthOverall} />
      <VesselDetail label=" " field=" " />
    </VesselArea>
  </Pane>
);

const MapDetails = ({ position }: { position?: Position; }) => {
  const hasPosition = position && Object.keys(position).length !== 0;
  return (
    <Pane title="Map" padding="5px" margin="10px">
      <MapPane>
        {hasPosition ? <MapContainer position={position} /> : <InformationBox text="No position returned from provider" />}
      </MapPane>
    </Pane>
  );
};

interface InterestDetailsProps { insurance?: Insurance; insuranceList?: Insurance[]; }
const InterestDetails = ({ insurance, insuranceList }: InterestDetailsProps) => {
  const filteredInsurances = insuranceList?.filter(
    item => item.interest !== insurance?.interest
  ) ?? [];
  return (
    <Pane title="Interest" padding="0px" margin="0px">
      {insurance && (
        <CoverPane>
          <CoverItemArea>
            <InsuranceDetail
              label="Interest: "
              field={insurance.interest} />
            <InsuranceDetail
              label="Value: "
              field={`${insurance.currency} ${formatNumber(insurance.sumInsured)}`} />
            <InsuranceDetail
              label="Broker: "
              field={insurance.broker} />
            <InsuranceDetail
              label="Insurance year: "
              field={insurance.insuranceYear} />
            <InsuranceDetail
              label="Fleet name: "
              field={insurance.fleetName} />
            <InsuranceDetail
              label="Our pct: "
              field={insurance.ourShare} />
            <InsuranceDetail
              label="Start date: "
              field={insurance.insurancePeriodFrom ?? '-'} />
            <InsuranceDetail
              label="Claimslead: "
              field={insurance.claimsLeader} />
            <InsuranceDetail
              label="End date: "
              field={insurance.insurancePeriodTo ?? '-'} />
            <InsuranceDetail
              label="Covernote: "
              field={insurance.coverNote} />
          </CoverItemArea>
        </CoverPane>
      )}

      <CoverPane>
        <CoverItemArea>
          {filteredInsurances.map((item, i) => (
            <span key={item.id}>
              <InsuranceDetail
                  label="Interest: "
                  field={item.interest} />
              <InsuranceDetail
                  label="Value: "
                  field={`${item.currency} ${formatNumber(item.sumInsured)}`} />
            </span>
          ))}
        </CoverItemArea>
      </CoverPane>
    </Pane>
  );
};

interface PositionDetailsProps { position?: Position; mapSettings: MapSettings; }
const PositionDetails = ({ position, mapSettings }: PositionDetailsProps) => (
  <Pane title="Position details" padding="0px" margin="0px">
    <PositionArea>
      <PositionDetail
        label="Latitude: "
        field={position?.latitudeDegree}
      />
      <PositionDetail
        label="Longitude: "
        field={position?.longitudeDegree}
      />
      <PositionDetail label="Speed: " field={position?.speed} />
      <PositionDetail label="Heading: " field={position?.heading} />
      <PositionDetail label="Course: " field={position?.course} />
      <PositionDetail label="Date: " field={position?.date} />
      <PositionDetail
        label="Zoom level: "
        field={mapSettings.zoom}
      />
    </PositionArea>
  </Pane>
);

export const SummaryPage = () => {
  const reportId = useReportId();
  const { report, position } = useReport();
  const mapSettings = useMapSettings();

  const commentText = useMarkdownTransformer(report?.comment?.text ?? '', report?.comment?.images ?? [], reportId);
  const commentIsLoading = commentText === null && report?.comment?.text !== null;

  return (
    <SummaryArea>
      <Content>
        <PageNavigator
          section={report?.vessel.imoNumber}
          title={report?.vessel.vesselName}
          toPagePrevious={`/${reportId}/comments`}
          toPageNext={`/${reportId}/distribution`}
        />
        <VesselInformation vessel={report?.vessel} />
        <MapDetails position={position} />
        <Categories category={report?.category} subCategory={report?.subCategory} />
        <Pane title="Interest and Position" padding="10px" margin="10px">
          <PositionArea>
            <PositionPane>
              <InterestDetails insurance={report?.insurance} insuranceList={report?.insuranceList} />
              <PositionDetails position={position} mapSettings={mapSettings} />
            </PositionPane>
          </PositionArea>
        </Pane>
        <Pane title="Comments" padding="5px" margin="10px">
          {commentIsLoading ? <Loader /> : <RichTextView markdown={commentText ?? ''} />}
        </Pane>
      </Content>
    </SummaryArea>
  );
};

export default SummaryPage;
