import React from 'react';
import styled from 'styled-components';
import { BoxButton } from '@instech/components';
import { Map } from '@instech/icons';
import { LabelFieldProps, Vessel } from '@/types';
import { useModalContext } from '../../modal/ModalContext';
import { openWrappedMapTool } from '../map/ModalMapTool';

const VesselArea = styled.div`
  margin: 10px auto 0px auto;
  padding: 0;
  background: #fff;
  max-width: 1200px;
`;

const VesselContainer = styled.div`
  margin-bottom: 0px;
  max-width: 1200px;
  align-content: center;
`;

const VesselHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
  grid-auto-flow: column;
  padding: 10px;
  background-color: ${props => props.theme.ultraLightGray};
  border-bottom: 1px solid ${props => props.theme.marineBlue};
`;

const VesselName = styled.div`
  font-size: 16px;
  align-self: center;
  color: ${props => props.theme.black};
`;

const ItemsArea = styled.div`
  background-color: ${props => props.theme.ultraLightGray};
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  grid-gap: 10px;
  padding: 20px;
`;

const ItemPane = styled.div`
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  align-items: flex-start;
  color: ${props => props.theme.black};
  font-weight: 200;
`;

const Item = styled.div`
  width: 100%;
`;

const ItemLabel = styled.div`
  font-weight: bold;
`;
const ItemField = styled.div`
  font-weight: normal;
`;

const VesselDetail = ({ label, field }: LabelFieldProps) => (
  <ItemPane>
    <Item>
      <ItemLabel>{label}</ItemLabel>
      <ItemField>{field}</ItemField>
    </Item>
  </ItemPane>
);

interface VesselInformationProps { vessel?: Vessel; }
const VesselInformation = ({ vessel }: VesselInformationProps) => {
  const { open } = useModalContext();

  if (!vessel) return null;
  return (
    <VesselArea>
      <VesselContainer>
        <VesselHeader>
          <VesselName>VESSEL INFORMATION</VesselName>
          <BoxButton
            inverted
            startIcon={<Map />}
            onClick={() => {
              open(openWrappedMapTool(vessel.imoNumber));
            }}
            width="120px"
            height="48px"
          >
            Location
          </BoxButton>
        </VesselHeader>
        <ItemsArea>
          <VesselDetail label="Vessel type" field={vessel.vesselType} />
          <VesselDetail label="Flag" field={vessel.flag} />
          <VesselDetail label="IMO number" field={vessel.imoNumber} />
          <VesselDetail label="Built" field={vessel.yearOfBuild} />
          <VesselDetail label="Width" field={vessel.breadthMoulded} />
          <VesselDetail label="Deadweight" field={vessel.deadWeightTonnage} />
          <VesselDetail
            label="Formula deadweight"
            field={vessel.formulaDeadweightTonnage}
          />
          <VesselDetail label="Gross tonnage" field={vessel.grossTonnage} />
          <VesselDetail label="Length" field={vessel.lengthOverall} />
        </ItemsArea>
      </VesselContainer>
    </VesselArea>
  );
};

export default VesselInformation;
