import React from 'react';
import { Link } from 'react-router-dom';
import { NhcLogo, NhcLogoSymbol } from '@instech/icons';
import styled from 'styled-components';
import { UserName } from './UserName';
import { PortalSelectorLoader } from './PortalSelectorLoader';

const NavArea = styled.div<{ scrolled: boolean }>`
  max-width: 100%;
  padding: 0px 30px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.theme.marineBlue};
  color: ${props => props.theme.white};
  position: ${props => props.scrolled && 'sticky'};
  top: 0;
  z-index: 40;
  height: ${props => props.scrolled ? props.theme.header.scrolledHeight : props.theme.header.height};
`;

const StyledImage = styled.div<{ scrolled: boolean }>`
  color: ${props => props.theme.white};
  & svg {
    height: ${props => props.scrolled ? '32px' : '64px'};
    width: 130px;
  }
`;

const Brand = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = ({ scrolled = false }) => (
  <StyledImage scrolled={scrolled}>
    {scrolled ? <NhcLogoSymbol alt="NHC logo" /> : <NhcLogo alt="NHC logo" />}
  </StyledImage>
);

const Name = styled.span`
  font-weight: bold;
  font-size: 20px;
  margin-left: 32px;
`;

const MenuSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

interface Props {
  title: string;
  scrolled: boolean;
}
const Navbar = ({ title, scrolled }: Props) => (
  <NavArea scrolled={scrolled}>
    <Brand>
      <Link to="/">
        <Logo scrolled={scrolled} />
      </Link>
      <Name>{title}</Name>
    </Brand>
    <MenuSection>
      <UserName />
      <PortalSelectorLoader />
    </MenuSection>
  </NavArea>
);

export default Navbar;
