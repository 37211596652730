import React from 'react';
import { createRoot } from 'react-dom/client';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { ErrorBoundary } from '@instech/components';
import { AuthenticationProvider } from './providers/authenticationProvider';
import { ModalHandler } from './modal/Modal';
import { CurrentRoleProvider } from './components/authentication/CurrentRoleContext';
import { ErrorPage } from './components/pages/error/ErrorPage';
import { App } from './App';
import { theme } from './utils/theme';
import { getJsonAsync } from './services/client';
import { FeatureFlagProvider } from './providers/featureFlagProvider';

import 'normalize.css';
import './fonts.scss';
import './custom.css';
import { MapProvider } from './providers/MapProvider';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const container = document.getElementById('root');
const root = createRoot(container);
const swrConfig = { fetcher: (...args) => getJsonAsync(...args) };

root.render(
  <ErrorBoundary>
    <AuthenticationProvider>
      <BrowserRouter basename={baseUrl}>
        <ThemeProvider theme={theme}>
          <ErrorBoundary component={ErrorPage}>
            <SWRConfig value={swrConfig}>
              <CurrentRoleProvider>
                <MapProvider>
                  <ModalHandler>
                    <FeatureFlagProvider>
                      <App />
                    </FeatureFlagProvider>
                  </ModalHandler>
                </MapProvider>
              </CurrentRoleProvider>
            </SWRConfig>
          </ErrorBoundary>
        </ThemeProvider>
      </BrowserRouter>
    </AuthenticationProvider>
  </ErrorBoundary>
);
