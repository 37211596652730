import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { childrenPropType } from '../../prop-types/custom-prop-types';

const StyledButton = styled.button`
  box-sizing: border-box;
  border: none;
  cursor: pointer;
  color: ${props => props.inverted ? props.theme.marineBlue : props.theme.white};
  background-color: ${props => props.inverted ? props.theme.white : props.theme.marineBlue};
  width: ${props => props && props.width};
  height: ${props => props && props.height};

  & svg {
    height: 25px;
  }

  &:hover {
    background-color: ${props =>
    props.inverted ? props.theme.white : props.theme.marineBlue75};
    border-color: ${props =>
    props.inverted ? props.theme.white : 'transparent'};
  }

  :disabled {
    color: ${props => props.inverted ? props.theme.black : props.theme.darkGray};
    background-color: ${props => props.inverted ? props.theme.ultraLightGray : props.theme.mediumGray};
    border-color: ${props => props.inverted ? props.theme.marineBlue50 : 'transparent'};
    cursor: default;
  }
`;

const TextContainer = styled.span`
  line-height: 18px;
  text-align: center;
  font-size: ${props => (props.fontSize ? props.fontSize : '13px')};
  font-weight: ${props => props.boldFont && 'bold'};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const IconContainer = styled.div`
  padding-right: ${props => props.start && '5px'};
  padding-left: ${props => props.end && '5px'};
  display: flex;
`;

export const MobileButton = ({
  children,
  inverted,
  startIcon: StartIcon,
  endIcon: EndIcon,
  width,
  height,
  fontSize,
  boldFont = true,
  onClick,
  disabled,
}) => (
  <StyledButton
    inverted={inverted}
    width={width}
    height={height}
    onClick={onClick}
    type="button"
    disabled={disabled}
  >
    <Container inverted={inverted}>
      {StartIcon && <IconContainer start="true">{StartIcon}</IconContainer>}
      <TextContainer fontSize={fontSize} boldFont={boldFont}>
        {children}
      </TextContainer>
      {EndIcon && <IconContainer end="true">{EndIcon}</IconContainer>}
    </Container>
  </StyledButton>
);

MobileButton.propTypes = {
  children: childrenPropType,
  inverted: PropTypes.bool,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  width: PropTypes.string,
  height: PropTypes.string,
  fontSize: PropTypes.string,
  boldFont: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
