import React, { ReactElement } from 'react';
import { useCategories, useSubCategories } from '@/services/CategoryService';
import { Category, SubCategory } from '@/types';
import styled from 'styled-components';
import { useReport } from '@/services/EmergencyReportService';
import { useReportId } from '@/hooks/useReportId';
import VesselInformation from '../components/vessel/VesselDetail';
import { PageNavigator } from '../shared/PageNavigator';

const PageArea = styled.div`
  min-height: calc(100vh - 60px);
  background-color: ${props => props.theme.whiteBlue};
  background-size: cover;
  width: 100%;
  margin: 0 auto;
`;

const Container = styled.div`
  min-height: calc(100vh - 60px);
  background-color: ${props => props.theme.whiteBlue};
  background-size: cover;
  padding-top: 10px 0px 0px 0px;
  margin: 0 auto;
  max-width: 1200px;
`;

const GridContainer = styled.div`
  padding: 10px;
  background-color: ${props => props.theme.lightBlue};
`;

const CategoryArea = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  grid-auto-flow: row;
  font-size: 12px;
  font-weight: bold;
  padding: 5px;
  background-color: ${props => props.theme.white};
`;

const SectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40px;
  padding-left: 20px;
  padding-right: 12px;
  border-bottom: 1px solid #7f9db8;
  margin-bottom: 10px;

  @media screen and (min-width: ${props => props.theme.breakpoints.pxPhone}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const SectionTitle = styled.span`
  color: ${props => props.theme.marineBlue};
  padding-top: 5px;
  padding-bottom: 5px;
`;

const CategoryGrid = styled.div`
  background-color: #ecf1f5;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-auto-rows: 30px;
  grid-gap: 10px 5px;
`;

const SubcategoryGrid = styled.div`
  background-color: #ecf1f5;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-auto-rows: 30px;
  grid-gap: 10px 5px;
`;

const ItemButton = styled.button`
  border: 1px solid blue;
  background-color: white;
  color: #000000;
  max-width: 150px;
  height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: bold;
  &:hover {
    background-color: blue;
    color: white;
  }
`;

interface CategoryLoaderProps {
  children: (subcategories: Category[]) => ReactElement;
}
const CategoryLoader = ({ children }: CategoryLoaderProps) => {
  const { data: categories } = useCategories();
  if (!categories) return null;
  return children(categories);
};

interface SubCategoryContainerProps {
  categories: Category[];
}
const CategoryContainer = ({ categories }: SubCategoryContainerProps) => {
  const { report, selectCategory } = useReport();
  const selectedCategory = report?.category;
  return (
    <GridContainer>
      <SectionHeader>
        <SectionTitle>Category</SectionTitle>
        {selectedCategory && (
          <SectionTitle>{selectedCategory.name}</SectionTitle>
        )}
      </SectionHeader>
      <CategoryGrid>
        {categories.map((category, i) => (
          <ItemButton
            key={category.id}
            onClick={() => {
              selectCategory(category);
            }}
          >
            {category.name}
          </ItemButton>
        ))}
      </CategoryGrid>
    </GridContainer>
  );
};

export const CategoryList = () => (
  <CategoryLoader>
    {categories => <CategoryContainer categories={categories} />}
  </CategoryLoader>
);

interface SubCategoryLoaderProps {
  children: (subcategories: SubCategory[]) => ReactElement;
}
const SubCategoryLoader = ({ children }: SubCategoryLoaderProps) => {
  const { report } = useReport();
  const { data: subCategories } = useSubCategories(report?.category?.id);
  if (!subCategories) return null;
  return children(subCategories);
};

interface SubCategoryContainerPRops {
  subCategories: SubCategory[];
}
const SubCategoryContainer = ({ subCategories }: SubCategoryContainerPRops) => {
  const { report, selectSubCategory } = useReport();
  const selectedSubCategory = report?.subCategory;
  return (
    <GridContainer>
      <SectionHeader>
        <SectionTitle>Sub Category</SectionTitle>
        {selectedSubCategory && (
          <SectionTitle>{selectedSubCategory.name}</SectionTitle>
        )}
      </SectionHeader>
      <SubcategoryGrid>
        {subCategories?.map((subCategory, i) => (
          <ItemButton
              key={subCategory.id}
              onClick={() => {
                selectSubCategory(subCategory);
              }}
            >
            {subCategory.name}
          </ItemButton>
        ))}
      </SubcategoryGrid>
    </GridContainer>
  );
};

export const SubCategoryList = () => (
  <SubCategoryLoader>
    {subCategories => <SubCategoryContainer subCategories={subCategories} />}
  </SubCategoryLoader>
);

function CategoryPage() {
  const reportId = useReportId();
  const { report } = useReport();
  const vessel = report?.vessel;

  return (
    <PageArea>
      <Container>
        <PageNavigator
          section={vessel?.imoNumber}
          title={vessel?.vesselName}
          toPagePrevious={`/${reportId}/insurance`}
          toPageNext={`/${reportId}/map`}
        />
        <VesselInformation
          key="1"
          vessel={vessel}
         />

        <CategoryArea>
          <CategoryList />
          <SubCategoryList />
        </CategoryArea>
      </Container>
    </PageArea>
  );
}

export default CategoryPage;
