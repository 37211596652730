import React, {
  createContext, useContext, ReactNode, useMemo
} from 'react';
import { MapSettings } from '@/types';

type MapSettingsContextData = {
  mapSettings: MapSettings;
  setMapSettings: (mapSettings: MapSettings) => void;
};

const MapSettingsContext = createContext<MapSettingsContextData | null>(null);

type MapSettingsProviderProps = {
  children: ReactNode;
};

export const MapProvider = ({ children }: MapSettingsProviderProps) => {
  const [mapSettings, setMapSettings] = React.useState<MapSettings>({ zoom: 5 });

  const value = useMemo(() => ({ mapSettings, setMapSettings }), [mapSettings, setMapSettings]);
  return (
    <MapSettingsContext.Provider value={value}>
      {children}
    </MapSettingsContext.Provider>
  );
};

export const useMapSettingsContext = () => useContext(MapSettingsContext) as MapSettingsContextData;

export const useMapSettings = () => useContext(MapSettingsContext)!.mapSettings;
