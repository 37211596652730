import axios, { AxiosError, AxiosResponse } from 'axios';

const client = axios.create({
  baseURL: '/api/',
  timeout: 30000
});

export const getJsonAsync = (endpoint: string) =>
  client.get(endpoint)
    .then(response => response.data);

export const getBlobAsync = (endpoint: string) =>
  client
    .get(endpoint, { responseType: 'blob', validateStatus: status => status < 400 || status === 404 })
    .then(response => response.data);

export function postJsonAsync<T = any>(endpoint: string, payload: any) {
  return client.post<T>(endpoint, payload)
    .then(response => response.data);
}

export function postAsync<T = any>(endpoint: string) {
  return client.post<T>(endpoint)
    .then(response => response.data);
}

export function putJsonAsync<ReturnType = any>(endpoint: string, payload: any) {
  return client.put<ReturnType>(endpoint, payload).then(response => response.data);
}

export function postFormAsync<ReturnType = any>(endpoint: string, form: any) {
  return client.post<ReturnType>(endpoint, form, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(response => response.data);
}

export function putFormAsync<ReturnType = any>(endpoint: string, form: any) {
  return client.put<ReturnType>(endpoint, form, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(response => response.data);
}

export const deleteAsync = (endpoint: string) => client.delete(endpoint);

function timeout(ms: number) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}

const defaultErrorRetryCount = 10;
const defaultErrorRetryInterval = 800;

function defaultRetryCondition<ReturnType>(response?: AxiosResponse<ReturnType>) {
  return response?.status === 404;
}

export interface RetryConfig<ReturnType> {
  retryCount?: number;
  errorRetryInterval?: number;
  retryCondition?: (errResponse: AxiosResponse<ReturnType>) => boolean;
}

export async function getWithRetry<ReturnType = any>(
  endpoint: string,
  config?: RetryConfig<ReturnType>
): Promise<ReturnType> {
  const retryCondition = config?.retryCondition ?? defaultRetryCondition;
  const retryCount = config?.retryCount ?? defaultErrorRetryCount;
  const retryInterval = config?.errorRetryInterval ?? defaultErrorRetryInterval;

  let response: AxiosResponse<ReturnType>;
  try {
    response = await client.get<ReturnType>(endpoint);
  } catch (err) {
    const typeErr = err as AxiosError<ReturnType>;

    if (!typeErr.response) throw typeErr;
    if (retryCount === 0 || !retryCondition(typeErr.response)) throw typeErr;
    response = typeErr.response;
  }

  const shouldRetry = retryCount !== 0 && retryCondition(response);

  if (shouldRetry) {
    await timeout(retryInterval);
    const nextConfig = {
      ...config,
      retryCount: retryCount - 1
    };
    return getWithRetry(endpoint, nextConfig);
  }

  return response?.data;
}
export default client;
