import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { childrenPropType } from '../prop-types/custom-prop-types';

const Container = styled.div`
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.06);
  margin: ${props => props.margin};
`;

const Title = styled.div`
  background-color: ${props => props.theme.flatWhite};
  color: ${props => props.theme.darkGray};
  font-size: 16px;
  font-weight: bold;
  display: flex;
`;

const TitleText = styled.div`
  padding: 12px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

const SubTitle = styled.div`
  background-color: ${props => props.theme.lightGray};
  color: ${props => props.theme.blackPearl};
  font-size: 13px;
  padding: 12px 20px;
`;

const Description = styled.div`
  color: ${props => props.theme.blue};
  font-size: 13px;
  font-weight: normal;
`;

const ButtonWrapper = styled.div`
  padding-right: 20px;
  align-self: center;
`;

const Content = styled.div`
  padding: ${props => props.padding ? props.padding : '20px'};
`;

export const Pane = ({ title, subTitle, description, button: Button, children, margin, padding }) => (
  <Container margin={margin}>
    <Title>
      <TitleText>
        {title}
        {description && <Description>{description}</Description>}
      </TitleText>
      {Button && <ButtonWrapper>{Button}</ButtonWrapper>}
    </Title>
    {subTitle && <SubTitle>{subTitle}</SubTitle>}
    <Content padding={padding}>{children}</Content>
  </Container>
);

Pane.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.node,
  children: childrenPropType.isRequired,
  margin: PropTypes.string,
  padding: PropTypes.string
};
