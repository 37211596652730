import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { IconButton } from '@instech/components';
import { Remove } from '@instech/icons';
import { childrenPropType } from '../prop-types/custom-prop-types';

const onClickHandler = fn => evt => evt.target === evt.currentTarget && fn();

const ModalScroll = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: ${props => props.zIndex || 1000};
  overflow-y: scroll;
`;

const Overlay = styled.div`
  width: 100%;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 40px;
  background-color: rgba(0,0,0,0.65);
  box-sizing: border-box;
`;

const Container = styled.div`
  pointer-events: none;
  background: none;
  position: relative;
  padding: 0px 16px;
  display: flex;
  justify-content: center;
`;

const LayoutArea = styled.div`
  pointer-events: auto;
  width: 100%;
  max-width: 1200px;
  background: #fff;
  border: 0;
  background-clip: padding-box;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  display: flex;
  flex-direction: column;

  ${props => props.size === 'medium' && css`
    max-width: 700px;
  `};

  ${props => props.size === 'small' && css`
    max-width: 512px;
  `};
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  padding-left: 20px;
  padding-right: 8px;
  background-color: ${props => props.theme.flatWhite};
`;

const ModalTitle = styled.span`
  padding-top: 11px;
  padding-bottom: 13px;
  font-weight: bold;
`;

const ModalBody = styled.div`
  padding: 20px;

  /* Impose spacing between direct descendants */
  > * ~ * {
    margin-top: 20px;
  }
`;

export const ModalLayout = ({ children, options = {}, closeModal, zIndex = 100 }) => (
  <ModalScroll zIndex={zIndex}>
    <Overlay onClick={onClickHandler(closeModal)}>
      <Container role="dialog">
        <LayoutArea size={options.size}>
          {options.title && (
            <ModalHeader>
              <ModalTitle>{options.title}</ModalTitle>
              <IconButton
                icon={<Remove />}
                onClick={closeModal} // Wrapping this in onClickHandler doesn't work?
              />
            </ModalHeader>
          )}
          <ModalBody>
            {children}
          </ModalBody>
        </LayoutArea>
      </Container>
    </Overlay>
  </ModalScroll>
);

ModalLayout.propTypes = {
  children: childrenPropType.isRequired,
  options: PropTypes.shape({
    title: PropTypes.string
  }),
  closeModal: PropTypes.func.isRequired,
  zIndex: PropTypes.number
};
