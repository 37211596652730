import useSWR from 'swr';
import { Category, SubCategory } from '@/types';

export const useCategories = () =>
  useSWR<Category[]>('category/categories/');

export const useSubCategories = (category?: string) =>
  useSWR<SubCategory[]>(category ? `category/subcategories/${category}` : null);

export const useSeedCategories = () =>
  useSWR<number>('category/seed/');
