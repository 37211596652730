import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { BoxButton } from '@instech/components';
import {
  addPositionAsync, distributeReportAsync, useReport
} from '@/services/EmergencyReportService';
import {
  Immediate,
  WarningFilled,
  InfoCircle,
} from '@instech/icons';
import { useMapSettings } from '@/providers/MapProvider';
import { useReportId } from '@/hooks/useReportId';
import { PageNavigator } from '../shared/PageNavigator';

const SummaryArea = styled.div`
  min-height: calc(100vh - 60px);
  background-color: ${props => props.theme.whiteBlue};
  background-size: cover;
  width: 100%;
  margin: 0 auto;
`;

const Content = styled.div`
  min-height: calc(100vh - 60px);
  background-color: ${props => props.theme.white};
  background-size: cover;
  padding-top: 10px 0px 0px 0px;
  margin: 0 auto;
  max-width: 1200px;
`;

const MessageArea = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const MessageRow = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: row;
`;

const ErrorIcon = styled(WarningFilled)`
  color: ${props => props.theme.lightRed};
  height: 30px;
  width: 30px;
  padding-right: 10px;
`;

const ErrorMessage = styled.div`
  padding-top: 5px;
  color: ${props => props.theme.lightRed};
  font-size: 16px;
  font-weight: bold;
`;

const WarningIcon = styled(InfoCircle)`
  color: ${props => props.theme.lightGreen};
  height: 30px;
  width: 30px;
  padding-right: 10px;
`;

const WarningMessage = styled.div`
  padding-top: 5px;
  color: ${props => props.theme.lightGreen};
  font-size: 16px;
  font-weight: bold;
`;

export const DistributionPage = () => {
  const history = useHistory();
  const id = useReportId();
  const { report, position } = useReport();
  const mapSettings = useMapSettings();
  const createReport = async () => {
    if (position) {
      await addPositionAsync(id, { position, mapSettings });
    }
    await distributeReportAsync(id);
    history.push('/');
  };
  return (
    <SummaryArea>
      <Content>
        <PageNavigator
          section={report?.vessel.imoNumber}
          title={report?.vessel.vesselName}
          toPagePrevious={`/${id}/summary`}
          toPageNext="/"
          label="Cancel report"
          showIcon={false}
        />
        <MessageArea>
          {!report?.insurance?.fleetName && (
          <MessageRow>
            <ErrorIcon />
            <ErrorMessage>
              Insurance not selected - please go back and select one
            </ErrorMessage>
          </MessageRow>
          )}

          {!report?.category?.name && (
            <MessageRow>
              <WarningIcon />
              <WarningMessage>Category not selected</WarningMessage>
            </MessageRow>
          )}

          {!report?.subCategory?.name && (
            <MessageRow>
              <WarningIcon />
              <WarningMessage>Sub Category not selected</WarningMessage>
            </MessageRow>
          )}

          {((report?.comment && Object.keys(report?.comment).length === 0) || !report?.comment) && (
            <MessageRow>
              <WarningIcon />
              <WarningMessage>Comments not registered</WarningMessage>
            </MessageRow>
          )}

          {!position && (
            <MessageRow>
              <WarningIcon />
              <WarningMessage>Position not found</WarningMessage>
            </MessageRow>
          )}

          {report?.insurance?.fleetName && (
            <BoxButton
              startIcon={<Immediate />}
              onClick={createReport}
              width="240px"
              height="96px"
            >
              CREATE PDF REPORT AND SEND EMAIL
            </BoxButton>
          )}
        </MessageArea>
      </Content>
    </SummaryArea>
  );
};

export default DistributionPage;
