import React, { useEffect, useState } from 'react';
import { childrenPropType } from '@/prop-types/custom-prop-types';
import {
  Redirect,
  Route,
  Switch
} from 'react-router';
import { ProtectedRoute } from '@/components/authentication/ProtectedRoute';
import { UnauthorizedPage } from '@/components/pages/error/UnauthorizedPage';
import { UpdatesPage } from '@/components/pages/UpdatesPage';

const ClearRolePage = () => {
  const [reload, setReload] = useState(false);
  useEffect(() => {
    localStorage.removeItem('role');
    setReload(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return reload ? <Redirect push exact to="/" /> : <div>Clearing Role</div>;
};

export const RootRouting = ({ children }) => (
  <Switch>
    <Route path="/unauthorized">
      <UnauthorizedPage />
    </Route>
    <Route exact path="/announcements">
      <UpdatesPage />
    </Route>
    <Route exact path="/clear-role">
      <ClearRolePage />
    </Route>
    <ProtectedRoute path="/">
      {children}
    </ProtectedRoute>
  </Switch>
);

RootRouting.propTypes = {
  children: childrenPropType
};
