import React from 'react';
import './custom.css';
import { RootRouting } from './components/authentication/RootRouting';
import { AppRouting } from './components/AppRouting';
import { Page } from './components/layout/Page';

export const App = () => (
  <RootRouting>
    <Page>
      <AppRouting />
    </Page>
  </RootRouting>
);
