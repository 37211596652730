import {
  $createRangeSelection, $getSelection, $isNodeSelection, $setSelection, LexicalEditor
} from 'lexical';
import { $isImageNode, ImagePayload } from '../../nodes/ImageNode';
import { INSERT_IMAGE_COMMAND } from '../../utils/commands';

export type InsertImagePayload = Readonly<ImagePayload>;

const getDOMSelection = (targetWindow: Window | null): Selection | null => (targetWindow ?? window).getSelection() ?? null;

const getImageNodeInSelection = () => {
  const selection = $getSelection();
  if (!$isNodeSelection(selection)) {
    return null;
  }
  const nodes = selection.getNodes();
  const node = nodes[0];
  return $isImageNode(node) ? node : null;
};

const TRANSPARENT_IMAGE =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
const img = document.createElement('img');
img.src = TRANSPARENT_IMAGE;

const getDragImageData = (event: DragEvent) => {
  const dragData = event.dataTransfer?.getData('application/x-lexical-drag');
  if (!dragData) {
    return null;
  }
  const { type, data } = JSON.parse(dragData);
  if (type !== 'image') {
    return null;
  }

  return data;
};

const canDropImage = (event: DragEvent) => {
  const { target } = event;
  return !!(
    target &&
    target instanceof HTMLElement &&
    !target.closest('code, span.editor-image') &&
    target.parentElement &&
    target.parentElement.closest('div.editor-input')
  );
};

const getTargetWindow = (target: Document | Element | null) => {
  if (target == null) {
    return null;
  }
  if (target.nodeType === 9) {
    return (target as Document).defaultView;
  }
  return (target as Element).ownerDocument.defaultView;
};

const getDragSelection = (event: DragEvent) => {
  let range;
  const target = event.target as null | Element | Document;
  const targetWindow = getTargetWindow(target);

  const domSelection = getDOMSelection(targetWindow);
  if (document.caretRangeFromPoint) {
    range = document.caretRangeFromPoint(event.clientX, event.clientY);
  } else if (event.rangeParent && domSelection !== null) {
    domSelection.collapse(event.rangeParent, event.rangeOffset ?? 0);
    range = domSelection.getRangeAt(0);
  } else {
    throw Error('Cannot get the selection when dragging');
  }

  return range;
};

export const onDragStart = (event: DragEvent) => {
  const node = getImageNodeInSelection();
  if (!node) {
    return false;
  }
  const { dataTransfer } = event;
  if (!dataTransfer) {
    return false;
  }
  dataTransfer.setData('text/plain', '_');
  dataTransfer.setDragImage(img, 0, 0);
  dataTransfer.setData(
    'application/x-lexical-drag',
    JSON.stringify({
      data: {
        altText: node.getAltText(),
        caption: node.getCaption(),
        height: node.getHeight(),
        key: node.getKey(),
        maxWidth: node.getWidth(),
        showCaption: node.getShowCapion(),
        src: node.getSrc(),
        width: node.getWidth(),
      },
      type: 'image',
    }),
  );

  return true;
};

export const onDragover = (event: DragEvent) => {
  const node = getImageNodeInSelection();
  if (!node) {
    return false;
  }
  if (!canDropImage(event)) {
    event.preventDefault();
  }
  return true;
};

export const onDrop = (event: DragEvent, editor: LexicalEditor) => {
  const node = getImageNodeInSelection();
  if (!node) {
    return false;
  }
  const data = getDragImageData(event);

  if (!data) {
    return false;
  }
  event.preventDefault();
  if (canDropImage(event)) {
    const range = getDragSelection(event);
    node.remove();
    const rangeSelection = $createRangeSelection();
    if (range !== null && range !== undefined) {
      rangeSelection.applyDOMRange(range);
    }
    $setSelection(rangeSelection);
    editor.dispatchCommand(INSERT_IMAGE_COMMAND, data);
  }
  return true;
};
