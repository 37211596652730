import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { BoxButton } from '@instech/components';
import { ArrowBack, ArrowForward } from '@instech/icons';
import { childrenPropType } from '../../prop-types/custom-prop-types';

export const NextButton = ({ onClick, label = 'Next' }) => (
  <BoxButton
    endIcon={<ArrowForward />}
    width="125px"
    height="50px"
    fontSize="18px"
    boldFont={false}
    onClick={onClick}
  >
    {label}
  </BoxButton>
);

NextButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
};

export const NextNavButton = ({
  onClick,
  to,
  label = 'Next',
  disabled,
  showIcon = true,
}) => (
  <Link to={to}>
    <BoxButton
    endIcon={showIcon && <ArrowForward />}
    width="125px"
    height="50px"
    margin-left="3px"
    fontSize="13px"
    boldFont={false}
    onClick={onClick}
    disabled={disabled}
  >
      {label}
    </BoxButton>
  </Link>
);

NextNavButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  to: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  showIcon: PropTypes.bool,
};

export const PreviousButton = ({ onClick }) => (
  <BoxButton
    inverted
    startIcon={<ArrowBack />}
    width="125px"
    height="50px"
    fontSize="18px"
    boldFont={false}
    onClick={onClick}
  >
    Previous
  </BoxButton>
);

PreviousButton.propTypes = {
  onClick: PropTypes.func,
};

export const PreviousNavButton = ({ onClick, to }) => (
  <Link to={to}>
    <BoxButton
      inverted
      startIcon={<ArrowBack />}
      width="125px"
      height="50px"
      margin-right="3px"
      fontSize="13px"
      boldFont={false}
      onClick={onClick}
    >
      Previous
    </BoxButton>
  </Link>
);

PreviousNavButton.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.string.isRequired,
};

// BoxButton wrapped in a React-Router Link
export const NavBox = ({
  children,
  inverted,
  startIcon: StartIcon,
  endIcon: EndIcon,
  width,
  height,
  to,
  onClick,
}) => (
  <Link to={to}>
    <BoxButton
      inverted={inverted}
      startIcon={StartIcon && <StartIcon />}
      endIcon={EndIcon && <EndIcon />}
      width={width}
      height={height}
      onClick={onClick}
    >
      {children}
    </BoxButton>
  </Link>
);

NavBox.propTypes = {
  children: childrenPropType,
  inverted: PropTypes.bool,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  width: PropTypes.string,
  height: PropTypes.string,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
