import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const CenterArea = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.whiteBlue};
  min-height: 100vh;

  @media (min-width: ${props => props.theme.breakpoints.pxPhone}) {
    max-width: calc(100vw / 2);
  }
`;

const Emergency = styled.div`
  color: ${props => props.theme.marineBlue};
  font-family: Calibri;
  font-size: 45px;
  font-weight: bold;
  padding: 15px;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  height: 50px;
  width: 340px;
  border: 1px solid ${props => props.theme.marineBlue};
  background-color: ${props => props.theme.marineBlue};
`;

const ButtonText = styled(Link)`
  color: ${props => props.theme.white};
  font-family: Calibri;
  font-size: 14px;
  line-height: 50px;
  text-decoration: none;
  text-transform: none;
  transition: 0ms;

  &:hover {
    color: ${props => props.theme.white};
  }
`;

const HomePage = () => (
  <CenterArea>
    <Emergency>Emergency</Emergency>
    <Button>
      <ButtonText to="/vessel">
        Press start button to activate the wizard
      </ButtonText>
    </Button>
  </CenterArea>
);

export default HomePage;
