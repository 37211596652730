import React from 'react';
import { useField } from 'formik';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { ListItemNode, ListNode } from '@lexical/list';
import { $convertFromMarkdownString, $convertToMarkdownString } from '@lexical/markdown';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { EditorState } from 'lexical';
import { ImageNode } from './nodes/ImageNode';
import { ImagesPlugin } from './plugins/ImagesPlugin';
import { ToolbarPlugin } from './plugins/ToolbarPlugin';
import DragDropPaste from './plugins/DragDropPastePlugin';
import EditorTheme from './themes/EditorTheme';
import { EDITOR_TRANSFORMERS } from './plugins/MarkdownTransformers';
import { Placeholder } from './ui/Placeholder';

interface EditorProps {
  name: string;
  withImageLoader?: boolean;
  initialState: string;
  onImageRemoved: (imageId: string) => void;
}

/**
 * ### A rich-text editor component
 *
 * The RichTextEditor component is a rich text editor from [Lexical text-editor framework](https://lexical.dev/).
 * It allows users to input and format text with various styles, fonts, and colors.
 *
 * The component accepts the property "name", which is the name of the input field, used as the key in the Formik.
 * The value of this formik field will be set to the EditorState's value,
 * transformered to markdown using [markdown helpers for Lexical](https://lexical.dev/docs/packages/lexical-markdown).
 *
 */

export const RichTextEditor: React.FC<EditorProps> = ({ name, withImageLoader, initialState, onImageRemoved }) => {
  const [{ value }, , helpers] = useField(name);

  const getInitialState = () => $convertFromMarkdownString(initialState, EDITOR_TRANSFORMERS);
  const editorConfig = {
    // The editor theme
    namespace: 'MyEditor',
    theme: EditorTheme,
    // Handling of errors during update
    onError(error: Error) {
      throw error;
    },
    // Any custom nodes go here
    nodes: [HeadingNode, ListNode, ListItemNode, QuoteNode, ImageNode],
    editorState: getInitialState,
  };

  const handleChange = (editorState: EditorState) => {
    editorState.read(() => {
      const markdown = $convertToMarkdownString(EDITOR_TRANSFORMERS) ?? '';

      helpers.setValue({ ...value, text: markdown });
    });
  };

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="editor-shell">
        <ToolbarPlugin withImageLoader={withImageLoader} />
        <DragDropPaste />
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={(
              <Placeholder className="editor-placeholder">
                Enter some text...
              </Placeholder>
            )}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          {withImageLoader && <ImagesPlugin onImageRemoved={onImageRemoved} />}
          <AutoFocusPlugin />
          <ListPlugin />
          <OnChangePlugin onChange={handleChange} />
          <TabIndentationPlugin />
        </div>
      </div>
    </LexicalComposer>
  );
};
