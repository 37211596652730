import { getImageUrlAsync, useImages } from '@/services/imageService';
import { ImageMap } from '@/types';
import { useEffect, useState } from 'react';

/**
 * Hook to replace markdown image references with blub urls.
 * This must be done outside the lexical markdown transformer because it does not support async operations.
 * The hook transforms the markdown image tag as follows:
 * ![alt text](imageId) -> ![alt text](reportId|imageId|filename|blobUrl)
 *
 * The markdown is further processed in MarkdownTransformers/index.ts, which converts to and from a Lexical ImageNode.
 * The reportId and imageId must be retained for later use when transforming back to markdown.
 * The pipe character is used as a delimiter to avoid collisions with url's.
 */

export const useMarkdownTransformer = (markdown: string, imageIds: string[], reportId: string) => {
  const [imagesMap, setImagesMap] = useState<ImageMap []>([]);
  const { data: images } = useImages(reportId);

  const getFileName = (imageId: string) => images?.find(x => x.id === imageId)?.fileName;

  useEffect(() => {
    async function getImageMap() {
      const mapImage = async (imageId: string) => getImageUrlAsync(reportId, imageId)
        .then(url => ({ match: imageId, imageId, url }));

      const imageArray = await Promise.all(imageIds.map(mapImage));
      setImagesMap(imageArray);
    }
    void getImageMap();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (imageIds.length === 0) return markdown;
  if (imagesMap.length === 0 || !images) return null;

  const result = imagesMap
    .reduce((acc, { imageId, match, url }) =>
      acc.replace(match, `${reportId}|${imageId}|${getFileName(imageId)}|${url}`),
    markdown
    );

  return result;
};
