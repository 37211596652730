import React from 'react';
import { Switch, Route } from 'react-router';
import HomePage from '../../pages//HomePage';
import { VesselPage } from '../../pages/VesselPage';
import GMap from '../../pages/MapPage';
import CategoryPage from '../../pages/CategoryPage';
import { InsurancePage } from '../../pages/InsurancePage';
import { CommentsPage } from '../../pages/CommentsPage';
import { SummaryPage } from '../../pages/SummaryPage';
import { DistributionPage } from '../../pages/DistributionPage';

export const LossPreventionRoutes = () => (
  <Switch>
    <Route exact path="/" component={HomePage} />
    <Route path="/vessel" component={VesselPage} />
    <Route path="/:reportId/map" component={GMap} />
    <Route path="/:reportId/category" component={CategoryPage} />
    <Route path="/:reportId/insurance" component={InsurancePage} />
    <Route path="/:reportId/comments" component={CommentsPage} />
    <Route path="/:reportId/summary" component={SummaryPage} />
    <Route path="/:reportId/distribution" component={DistributionPage} />
  </Switch>
);
