import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { InfoCircle } from '@instech/icons';

const Container = styled.div`
  display: flex;
  background-color: #fff;
  padding: 12px 24px 12px 12px;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.06);
  flex-grow: 1;

  & svg {
    flex-shrink: 0;
  }
`;

const Content = styled.div`
  margin: 3px 0px 5px 10px;
  font-size: 16px;
  font-weight: bold;
`;

export const InformationBox = ({ text }) => (
  <Container>
    <InfoCircle />
    <Content>{text}</Content>
  </Container>
);

InformationBox.propTypes = {
  text: PropTypes.string.isRequired
};
