import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { ErrorView } from './core/ErrorView';
import { ErrorTypes } from './core/ErrorTypes';

export const ErrorPage = ({ reset }) => {
  const location = useLocation();
  const [prevLocation] = useState(location);

  if (location !== prevLocation) {
    reset(); // Resets error boundary if user navigates away from page
  }
  return (
    <ErrorView
      reset={reset}
      type={ErrorTypes.notAllowed}
      title="Oh no..."
      message="Something went wrong here. It's not you. It's us."
  />
  );
};

ErrorPage.propTypes = {
  reset: PropTypes.func.isRequired
};
