import React from 'react';
import { ApplicationRole } from '@/types';
import styled from 'styled-components';
import { Pane } from '../../shared/Pane';
import { saveUserRole } from './roles';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const StyledButton = styled.button`
  cursor: pointer;
  display: block;
  width: 200px;
  height: 40px;
  margin: 20px;
`;

interface SelectRoleProps {
  roles: ApplicationRole[];
  setCurrentRole: (props: ApplicationRole) => void;
}
export const SelectRole = ({ roles, setCurrentRole }: SelectRoleProps) => {
  const onSetRole = (role: ApplicationRole) => {
    saveUserRole(role);
    setCurrentRole(role);
  };
  return (
    <Wrapper>
      <Pane title="Select a role before continuing">
        {roles.map(role =>
          <StyledButton key={role.name} onClick={() => onSetRole(role)}>{role.displayName}</StyledButton>)}
      </Pane>
    </Wrapper>
  );
};
