import React from 'react';
import { useAccount } from '@/providers/authenticationProvider';
import { UserMenu } from 'insify-remote-component-loader';
import { useHistory } from 'react-router-dom';
import { useCurrentRole } from '@/components/authentication/CurrentRoleContext';

export const UserMenuLoader = () => {
  const account = useAccount();
  const role = useCurrentRole();
  const history = useHistory();
  const links = [
    {
      href: '/announcements',
      title: 'Announcements',
    },
    {
      title: 'Support',
      onClick: () => {
        window.open('https://instech.atlassian.net/servicedesk/customer/portal/17', '_blank');
      },
    },
  ];
  if (!account?.isAuthenticated) return null;
  return (
    <UserMenu
      account={account}
      history={history}
      role={role}
      portal={{ name: 'emergency', displayName: 'Emergency' }}
      links={links}
    />
  );
};
