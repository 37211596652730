import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useWindowSize from '@/services/WindowSize';
import { BoxButton } from '@instech/components';
import { Map, CheckThin } from '@instech/icons';
import { LabelFieldProps, Vessel } from '@/types';
import { useReport } from '@/services/EmergencyReportService';
import { useModalContext } from '../../modal/ModalContext';
import { openWrappedMapTool } from '../map/ModalMapTool';

const VesselArea = styled.div`
  margin: 0 auto;
  padding: 0;
  background: #fff;
  max-width: 1200px;
`;

const VesselContainer = styled.div`
  margin-bottom: 10px;
  max-width: 1200px;
  align-content: center;
`;

const VesselHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
  grid-auto-flow: column;
  border: 1px solid blue;
  padding: 10px;
  background-color: ${props => props.theme.ultraLightGray};
`;

const VesselName = styled.div`
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  align-self: center;
  color: ${props => props.theme.marineBlue};
`;

const ItemsArea = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  grid-gap: 10px;
  border-bottom: 1px solid ${props => props.theme.marineBlue};
  border-left: 1px solid ${props => props.theme.marineBlue};
  border-right: 1px solid ${props => props.theme.marineBlue};
  padding: 20px;
`;

const ItemPane = styled.div`
  background: rgba(255, 255, 255, 0.2);
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  align-items: flex-start;
  color: ${props => props.theme.black};
  font-weight: 200;
`;

const Item = styled.div`
  width: 100%;
`;

const ItemLabel = styled.div`
  font-weight: bold;
`;
const ItemField = styled.div`
  font-weight: normal;
`;

const MobileFooter = styled.div`
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const VesselDetail = ({ label, field }: LabelFieldProps) => (
  <ItemPane>
    <Item>
      <ItemLabel>{label}</ItemLabel>
      <ItemField>{field}</ItemField>
    </Item>
  </ItemPane>
);

interface VesselListItemProps { vessel: Vessel; }
const VesselListItem = ({ vessel }: VesselListItemProps) => {
  const size = useWindowSize();
  const history = useHistory();
  const { open } = useModalContext();
  const [reportId, setReportId] = useState<string | null>(null);
  const { selectVessel } = useReport();

  useEffect(() => {
    // if the reportId has been set, than means that the user has created a draft, and we can redirect to the vessel page
    if (reportId) {
      history.push(`/${reportId}/insurance`);
    }
  }, [reportId, history]);

  if (!vessel) return null;
  return (
    <VesselArea>
      <VesselContainer>
        <VesselHeader>
          <VesselName>{vessel.vesselName}</VesselName>
          {size && !size.isMobile && (
            <>
              <BoxButton
                inverted
                startIcon={<Map />}
                onClick={() => {
                  open(openWrappedMapTool(vessel.imoNumber));
                }}
                width="120px"
                height="48px"
              >
                Location
              </BoxButton>
              <BoxButton
                startIcon={<CheckThin />}
                onClick={async () => {
                  await selectVessel(vessel).then(setReportId);
                }}
                width="120px"
                height="48px"
              >
                Select
              </BoxButton>
            </>
          )}
        </VesselHeader>
        <ItemsArea>
          <VesselDetail label="Vessel type" field={vessel.vesselType} />
          <VesselDetail label="Flag" field={vessel.flag} />
          <VesselDetail label="IMO number" field={vessel.imoNumber} />
          <VesselDetail label="Built" field={vessel.yearOfBuild} />
          <VesselDetail label="Width" field={vessel.breadthMoulded} />
          <VesselDetail label="Deadweight" field={vessel.deadWeightTonnage} />
          <VesselDetail
            label="Formula deadweight"
            field={vessel.formulaDeadweightTonnage}
          />
          <VesselDetail label="Gross tonnage" field={vessel.grossTonnage} />
          <VesselDetail label="Length" field={vessel.lengthOverall} />
        </ItemsArea>
        {size?.isMobile && (
          <MobileFooter>
            <BoxButton
              inverted
              startIcon={<Map />}
              onClick={() => {
                open(openWrappedMapTool(vessel.imoNumber));
              }}
              width="120px"
              height="48px"
            >
              Location
            </BoxButton>
            <BoxButton
              startIcon={<CheckThin />}
              onClick={async () => {
                await selectVessel(vessel).then(setReportId);
              }}
              width="120px"
              height="48px"
            >
              Select
            </BoxButton>
          </MobileFooter>
        )}
      </VesselContainer>
    </VesselArea>
  );
};

export default VesselListItem;
