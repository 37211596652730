import React from 'react';
import { InformationBox } from '@/shared/InformationBox';
import styled from 'styled-components';
import { useReportId } from '@/hooks/useReportId';
import { useReport } from '@/services/EmergencyReportService';
import { WrappedMap } from '../components/map/WrappedMap';
import { PageNavigator } from '../shared/PageNavigator';

const MapWrapper = styled.div`
  min-height: calc(100vh - 60px);
  background-color: ${props => props.theme.whiteBlue};
  background-size: cover;
`;

const MapArea = styled.div`
  min-height: calc(100vh - 60px);
  max-width: 1200px;
  background-color: ${props => props.theme.whiteBlue};
  background-size: cover;
  margin: 0 auto;
`;

const MapContainer = styled.div`
  height: calc(100vh - 195px);
`;

const GMap = () => {
  const reportId = useReportId();
  const { report, position } = useReport();
  const missingPosition = !position || (position && Object.keys(position).length === 0);
  return (
    <MapWrapper>
      <MapArea>
        <PageNavigator
          section={report?.vessel.imoNumber}
          title={report?.vessel.vesselName}
          toPagePrevious={`/${reportId}/category`}
          toPageNext={`/${reportId}/comments`}
        />
        <MapContainer>
          <WrappedMap />
          {missingPosition && (
            <InformationBox text="No position returned from provider" />
          )}
        </MapContainer>
      </MapArea>
    </MapWrapper>
  );
};

export default GMap;
