import React from 'react';
import PropTypes from 'prop-types';
import { ErrorView } from './core/ErrorView';
import { ErrorTypes } from './core/ErrorTypes';

const errorMessage = `
  The request has not been applied because it lacks valid
  authentication credentials for the target resource.
`;

export const UnauthorizedPage = ({ reset }) => (
  <ErrorView
    reset={reset}
    type={ErrorTypes.unauthorized}
    title="Unauthorized"
    message={errorMessage}
  />
);

UnauthorizedPage.propTypes = {
  reset: PropTypes.func
};
