import { useHasScrolled } from '@/hooks/useHasScrolled';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Navbar from '../header/Navbar';

const PageWrapper = styled.div<{scrolled?: boolean}>`
  min-height: 100vh;
  margin-top: ${props => props.scrolled ? '52px' : null};
`;

interface Props { title?: string; }
export const Page: FunctionComponent<Props> = ({ children, title }) => {
  const scrolledHeight = 52;
  const hasScrolled = useHasScrolled(scrolledHeight);

  return (
    <PageWrapper scrolled={hasScrolled}>
      <Navbar title={title ?? 'Emergency'} scrolled={hasScrolled} />
      {children}
    </PageWrapper>
  );
};
