import { ApplicationRole } from '@/types';
import React, {
  createContext, useContext, FunctionComponent, useState, useMemo
} from 'react';

interface RoleContextData
{
  currentRole: ApplicationRole | null;
  setCurrentRole: (props: ApplicationRole) => void;
}
const CurrentRoleContext = createContext<RoleContextData| null>(null);

interface CurrentRoleProviderProps {
  applicationRole: ApplicationRole | null;
}
export const CurrentRoleProvider: FunctionComponent<CurrentRoleProviderProps> = ({ children, applicationRole = null }) => {
  const [currentRole, setCurrentRole] = useState<ApplicationRole | null>(applicationRole);
  const value = useMemo(() => ({ currentRole, setCurrentRole }), [currentRole, setCurrentRole]);
  return (
    <CurrentRoleContext.Provider value={value}>
      {children}
    </CurrentRoleContext.Provider>
  );
};

export const useCurrentRole = () => useContext(CurrentRoleContext)?.currentRole;
export const useCurrentRoleContext = () => useContext(CurrentRoleContext) as RoleContextData;
