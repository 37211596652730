import { PortalSelector } from 'insify-remote-component-loader';
import { useAccount } from '@/providers/authenticationProvider';
import React from 'react';

export const PortalSelectorLoader = () => {
  const account = useAccount();

  return (
    <PortalSelector
      account={account}
      portal={{ name: 'emergency', displayName: 'Emergency' }}
    />
  );
};
