import * as React from 'react';
import { ReactNode } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  font-size: 15px;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
  display: inline-block;
  pointer-events: none;
`;

export const Placeholder = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}): JSX.Element => <Wrapper className={className || 'Placeholder__root'}>{children}</Wrapper>;
