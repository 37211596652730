import { ApplicationRole } from '@/types';
import { now } from 'moment';

export const roles = {
  lossPrevention: 'LP-ER',
};

interface StoredRole {
  expiry: number;
  userRole: ApplicationRole;
}
export const getUserRole = (): ApplicationRole | null => {
  const storedRole = localStorage.getItem('role');
  if (!storedRole) return null;
  const { userRole, expiry }: StoredRole = JSON.parse(storedRole);
  const hasExpired = now() > expiry;
  if (hasExpired) return null;
  return userRole;
};

export const saveUserRole = (role: ApplicationRole) => {
  const dayInMilliseconds = 86400000;
  const expires = now() + dayInMilliseconds;
  const userRole = JSON.stringify({ userRole: role, expiry: expires });
  localStorage.setItem('role', userRole);
};
