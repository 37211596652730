import useSWR from 'swr';
import {
  postFormAsync, getBlobAsync, deleteAsync
} from './client';

const imageApiRoute = (reportId: string) => `image/${reportId}`;

export interface Image {
  id: string;
  title: string;
  fileName: string;
  originalFileName: string;
}
export const uploadImageAsync = async (reportId: string, image: File, title: string) => {
  const formData = new FormData();
  formData.append('image', image);
  formData.append('title', title);

  const result = await postFormAsync<Image>(imageApiRoute(reportId), formData);
  return result;
};

export const getImageUrlAsync = async (reportId: string, imageId: string) =>
  getBlobAsync(`${imageApiRoute(reportId)}/${imageId}`)
    .then(blob => new Blob([blob]))
    .then(URL.createObjectURL);

export const useImages = (reportId: string) =>
  useSWR<Image[]>(imageApiRoute(reportId));

export const deleteImageAsync = async (reportId: string, imageId: string) =>
  deleteAsync(`${imageApiRoute(reportId)}/${imageId}`);
