import React from 'react';
import styled from 'styled-components';
import { UserMenuLoader } from './UserMenuLoader';

const Wrapper = styled.div`
  font-size: 14px;
  a {
    text-decoration: none;
  }
`;

export const UserName = () => (
  <Wrapper>
    <UserMenuLoader />
  </Wrapper>
);
