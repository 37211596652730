import useSWR from 'swr';
import { ApplicationRole } from '@/types';

export const useRoles = () => {
  const requestUrl = '/roles';
  return useSWR<ApplicationRole[]>(requestUrl, () => [{
    name: 'LP-ER',
    displayName: 'LP-ER'
  }]);
};
