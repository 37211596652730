/* eslint-disable react/forbid-prop-types */
import React, {
  createContext, useContext, useMemo
} from 'react';
import PropTypes from 'prop-types';
import { childrenPropType } from '../prop-types/custom-prop-types';

const initialValues = {
  open: component => {},
  close: () => {}
};

const ModalContext = createContext(initialValues);

export const ModalProvider = ({ children, open, close }) => {
  const values = useMemo(() => ({ open, close }), [open, close]);
  return (
    <ModalContext.Provider value={values}>
      {children}
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  open: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  children: childrenPropType
};

export const useModalContext = () => useContext(ModalContext);
