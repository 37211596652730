import { deleteImageAsync, uploadImageAsync } from '@/services/imageService';
import { Buffer } from 'buffer';
import { RichText } from '../../RichTextForm/RichTextForm';

/** *
 * Converts a dataUrl base64 image string into a File byte array
 * dataUrl example:
 * data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIsAAACLCAYAAABRGWr/AAAAAXNSR0IA...etc
 */
const dataUrlToFile = (dataUrl: string, filename: string): File | undefined => {
  const arr = dataUrl.split(',');
  if (arr.length < 2) return undefined;

  const mimeArr = RegExp(/:(.*?);/).exec(arr[0]);
  if (!mimeArr || mimeArr.length < 2) return undefined;

  const mime = mimeArr[1];
  const buff = Buffer.from(arr[1], 'base64');

  return new File([buff], filename, { type: mime });
};

const getImageFromMatch = async (match: RegExpMatchArray, reportId: string) => {
  const [, altText, content] = match;
  const [, imageId, fileName, src] = content.split('|');

  // Existing image, no need to upload
  if (imageId !== 'undefined') return { id: imageId, title: altText };

  const file = dataUrlToFile(src, fileName);
  if (!file) throw new Error('Failed to convert dataUrl to File');

  const image = await uploadImageAsync(reportId, file, altText);

  return { id: image.id, title: image.title };
};

const addImageIfUnique = (images: string[], imageId: string) =>
  images.some(id => id === imageId) ? images : [...images, imageId];

const replaceImageMarkdown = async (text: string, images: string[], matches: RegExpMatchArray[], reportId: string) => {
  const result = await matches.reduce(async (accP, match) => {
    const acc = await accP;
    const { id, title } = await getImageFromMatch(match, reportId);
    const imageMarkdown = `![${title ?? ''}](${id})`;
    return { text: acc.text.replace(match[0], imageMarkdown), images: addImageIfUnique(acc.images, id) };
  }, Promise.resolve({ text, images }));
  return result;
};

export const transformMarkdown = async ({ text, images }: RichText, deletedImages: string[], reportId: string) => {
  if (!text) return null;
  const re = /!(?:\[([^[]*)\])(?:\(([^(]+)\))/g;
  const matches = [...text.matchAll(re)];

  const result = await replaceImageMarkdown(text, images, matches, reportId);

  if (deletedImages.length > 0) {
    const deletionPromises = deletedImages.map(imageId => deleteImageAsync(reportId, imageId));
    await Promise.all(deletionPromises);
    return { ...result, images: result.images.filter(imageId => !deletedImages.includes(imageId)) };
  }
  return result;
};
