import React, { FunctionComponent } from 'react';
import { useRoles } from '@/services/rolesServices';
import { ApplicationRole } from '@/types';
import styled from 'styled-components';
import { SelectRole } from './SelectRole';
import {
  roles,
  getUserRole,
  saveUserRole
} from './roles';

import { LossPreventionRoutes } from './LossPreventionRoutes';
import { useCurrentRoleContext } from '../authentication/CurrentRoleContext';

const NoAccessArea = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 60px);
  color: white;
  font-size: 84px;
  justify-content: center;
  align-items: center;
`;

const NoAccess = () => <NoAccessArea>No Access</NoAccessArea>;

const RoleRouting: FunctionComponent<ApplicationRole> = ({ name }) =>
  name === roles.lossPrevention ? <LossPreventionRoutes /> : <NoAccess />;

interface RoleSelectorProps {
  setCurrentRole: (props: ApplicationRole) => void;
}
const RoleSelector = ({ setCurrentRole }: RoleSelectorProps) => {
  const { data: userRoles } = useRoles();
  if (!userRoles) return null;
  if (userRoles.length < 1) return <NoAccess />;

  const role = getUserRole();
  if (role && userRoles.find(p => p.name === role.name)) {
    setCurrentRole(role);
    return null;
  }

  if (userRoles.length === 1) {
    const activeRole = userRoles[0];
    setCurrentRole(activeRole);
    saveUserRole(activeRole);
    return null;
  }

  return <SelectRole roles={userRoles} setCurrentRole={setCurrentRole} />;
};

export const AppRouting = () => {
  const { currentRole, setCurrentRole } = useCurrentRoleContext();
  if (currentRole) {
    return <RoleRouting {...currentRole} />;
  }

  return <RoleSelector setCurrentRole={setCurrentRole} />;
};
