import React from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { ListItemNode, ListNode } from '@lexical/list';
import { $convertFromMarkdownString } from '@lexical/markdown';
import EditorTheme from '../RichTextEditor/themes/EditorTheme';
import { EDITOR_TRANSFORMERS } from '../RichTextEditor/plugins/MarkdownTransformers';
import { ImageNode } from '../RichTextEditor/nodes/ImageNode';

interface EditorProps {
  markdown: string;
}

/**
 * ### A rich-text editor component
 *
 * The RichTextEditor component is a rich text editor from [Lexical text-editor framework](https://lexical.dev/).
 * It allows users to input and format text with various styles, fonts, and colors.
 *
 * The component accepts the property "name", which is the name of the input field, used as the key in the Formik.
 * The value of this formik field will be set to the EditorState's value,
 * transformered to markdown using [markdown helpers for Lexical](https://lexical.dev/docs/packages/lexical-markdown).
 *
 */
export const RichTextView: React.FC<EditorProps> = ({ markdown }) => {
  const editorConfig = {
    // The editor theme
    namespace: 'MyEditor',
    theme: EditorTheme,
    onError(error: Error) {
      throw error;
    },
    // Any custom nodes go here
    nodes: [HeadingNode, ListNode, ListItemNode, QuoteNode, ImageNode],
    editorState: () => $convertFromMarkdownString(markdown ?? '', EDITOR_TRANSFORMERS),
  };

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="editor-shell">
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={null}
            ErrorBoundary={LexicalErrorBoundary}
          />
        </div>
      </div>
    </LexicalComposer>
  );
};
