import React from 'react';
import styled from 'styled-components';
import { Birds } from '@instech/icons';

const BirdContainer = styled.div`
  position: absolute;
  top: 56px;
  left: 50%;
  width: 100%;
  max-width: 532px;
  padding: 0px 16px;
  transform: translateX(-50%);
  box-sizing: border-box;

  @media screen and (max-width: 600px) {
    top: 24px;
    max-width: 432px;

    svg {
      width: 70px;
      height: auto;
    }
  }
`;

export const ErrorBirds = () => (
  <BirdContainer>
    <Birds />
  </BirdContainer>
);
