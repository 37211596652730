/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  label {
    display: flex;
    flex: 1;
    color: #666;
  }
  input {
    display: flex;
    flex: 2;
    border: 1px solid #999;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    border-radius: 5px;
    min-width: 0;
  }
`;

type Props = Readonly<{
  'data-test-id'?: string;
  accept?: string;
  label: string;
  onChange: (files: FileList | null) => void;
}>;

export const FileInput = ({
  accept,
  label,
  onChange,
  'data-test-id': dataTestId,
}: Props): JSX.Element => (
  <Wrapper>
    <label className="Input__label">{label}</label>
    <input
        type="file"
        accept={accept}
        className="Input__input"
        onChange={e => onChange(e.target.files)}
        data-test-id={dataTestId}
      />
  </Wrapper>
);
