import React from 'react';
import styled from 'styled-components';
import { Wave } from '@instech/icons';

const WaveOverflowWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px;
  overflow: hidden;
`;

const WaveContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  animation: wave 2s linear infinite;

  & svg {
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @keyframes wave {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(68px);
    }

  }
`;

export const ErrorWave = () => (
  <WaveOverflowWrapper>
    <WaveContainer>
      <Wave />
    </WaveContainer>
  </WaveOverflowWrapper>
);
