import React from 'react';
import PropTypes from 'prop-types';
import useWindowSize from '@/services/WindowSize';
import styled from 'styled-components';
import { NextNavButton, PreviousNavButton } from './BoxButton/BoxButtons';
import {
  NextMobileNavButton,
  PreviousMobileNavButton,
} from './BoxButton/MobileButtons';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-end;
  padding: 5px;
  color: ${props => props.theme.marineBlue};
  background-color: ${props => props.theme.lightBlue};
  h3 {
    font-weight: bold;
    font-size: 16px;
    margin: 0;
    margin-left: 2px;
  }
  h2 {
    margin: 0;
    font-size: 42px;
    font-weight: 400;
    text-transform: uppercase;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.pxPhone}) {
    padding: 33px 30px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: ${props => props.justify || ''};
  width: 300px;

  & > button + button {
    margin-left: 16px;
  }
`;

const TitleArea = styled.div`
  max-width: 1200px;
`;

const MobileWrapper = styled.div`
  max-width: 100%;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.white};
`;

export const PageNavigator = ({
  section,
  title,
  toPagePrevious,
  toPageNext,
  disabled,
  label,
  showIcon = true,
}) => {
  const size = useWindowSize();
  const nextButtonLabel = label || 'Next';

  return (
    <div>
      {size && size.isMobile && (
        <MobileWrapper>
          {toPagePrevious && toPagePrevious !== '' && (
            <PreviousMobileNavButton to={toPagePrevious} />
          )}
          {toPageNext && toPageNext !== '' && (
            <NextMobileNavButton
              to={toPageNext}
              disabled={disabled}
              label={nextButtonLabel}
              showIcon={showIcon}
            />
          )}
        </MobileWrapper>
      )}
      <Header>
        <TitleArea>
          <h3>{section}</h3>
          <h2>{title}</h2>
        </TitleArea>
        {size && !size.isMobile && (
          <ButtonWrapper justify="space-evenly">
            <PreviousNavButton
              to={toPagePrevious}
              disabled={toPagePrevious === ''}
            />
            <NextNavButton
              to={toPageNext}
              disabled={toPageNext === ''}
              label={nextButtonLabel}
              showIcon={showIcon}
            />
          </ButtonWrapper>
        )}
      </Header>
    </div>
  );
};

PageNavigator.propTypes = {
  section: PropTypes.number,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  showIcon: PropTypes.bool,
  title: PropTypes.string,
  toPagePrevious: PropTypes.string.isRequired,
  toPageNext: PropTypes.string.isRequired,
};
