import React, {
  useState, useEffect, ReactElement
} from 'react';
import styled from 'styled-components';
import { useVessels } from '@/services/VesselService';
import { InformationBox } from '@/shared/InformationBox';
import { Vessel } from '@/types';
import VesselListItem from '../components/vessel/VesselListItem';
import SearchField from '../components/SearchField/SearchField';

const VesselArea = styled.div`
  min-height: calc(100vh - 60px);
  background-color: ${props => props.theme.whiteBlue};
  background-size: cover;

  @media (min-width: ${props => props.theme.breakpoints.pxPhone}) {
    padding-top: 10px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background-color: ${props => props.theme.white};
  max-width: 1200px;
  height: 160px;

  @media (min-width: ${props => props.theme.breakpoints.pxPhone}) {
    margin: 30px auto 15px auto;
  }
`;

const HeaderTop = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  border-bottom: 2px solid ${props => props.theme.marineBlue};
`;

const HeaderText = styled.div`
  align-self: center;
  margin-left: 20px;
  margin-right: 20px;
  color: ${props => props.theme.deepAzureBlue};
  font-family: Calibri;
  font-size: 16px;
`;

const SearchArea = styled.div`
  height: 43px;
  color: ${props => props.theme.deepAzureBlue};
  font-family: Calibri;
  font-size: 42px;
  line-height: 42px;
  text-align: center;
  width: calc(100% - 40px);
  margin-left: 20px;
`;

const SearchHelperText = styled.div`
  padding-top: 10px;
  color: ${props => props.theme.marineBlue};
  font-family: Calibri;
  font-size: 14px;
  line-height: 14px;
  text-align: left;
  margin-left: 20px;
`;

const InfoContainer = styled.div`
  background-color: ${props => props.theme.white};
  max-width: 1200px;
  @media (min-width: ${props => props.theme.breakpoints.pxPhone}) {
    margin: 30px auto 15px auto;
  }
`;

interface VesselContainerProps {
  vessels: Vessel[];
}
const VesselContainer = ({ vessels }: VesselContainerProps) => {
  if (vessels && vessels.length === 0) {
    return (
      <InfoContainer>
        <InformationBox text="Search returned no vessels with active insurance, please check and/or retry" />
      </InfoContainer>
    );
  }
  return (
    <>
      {vessels.map(vessel => (
        <VesselListItem key={vessel.id} vessel={vessel} />
      ))}
    </>
  );
};

interface VesselLoaderProps {
  children: (vessels: Vessel[]) => ReactElement;
  searchString: string;
  setNumberOfVessels: (nbrVessels: number) => void;
}
const VesselLoader = ({ children, searchString, setNumberOfVessels }: VesselLoaderProps) => {
  const urlSearchString = encodeURI(searchString);
  const { data: vessels } = useVessels(urlSearchString);

  useEffect(() => {
    const nbrVessels = vessels?.length ?? 0;
    setNumberOfVessels(nbrVessels);
  }, [vessels, setNumberOfVessels]);

  if (!vessels) return null;
  return children(vessels);
};

interface VesselContentProps {
  searchString: string | null;
  setNumberOfVessels: (nbrVessels: number) => void;
}
const VesselContent = ({ searchString, setNumberOfVessels }: VesselContentProps) => {
  if (searchString === null || searchString === '') return null;
  return (
    <VesselLoader
      searchString={searchString}
      setNumberOfVessels={setNumberOfVessels}
    >
      {vessels => <VesselContainer vessels={vessels} />}
    </VesselLoader>
  );
};

export const VesselPage = () => {
  const [searchString, setSearchString] = useState('');
  const [activeSearch, setActiveSearch] = useState<string | null>(null);
  const [numberOfVessels, setNumberOfVessels] = useState(0);

  const getVessels = (searchText: string) => {
    setActiveSearch(searchText);
  };

  return (
    <VesselArea>
      <Container>
        <HeaderTop>
          <HeaderText>Find vessel</HeaderText>
          <HeaderText>
            {numberOfVessels}
            {' '}
            Result(s)
          </HeaderText>
        </HeaderTop>
        <SearchArea>
          <SearchField
            onChange={value => setSearchString(value)}
            searchText={searchString}
            search={getVessels}
          />
          <SearchHelperText>
            XXXXX = Starts with, %XXXXX = Contains or ends with
          </SearchHelperText>
        </SearchArea>
      </Container>
      <VesselContent
        searchString={activeSearch}
        setNumberOfVessels={setNumberOfVessels}
      />
    </VesselArea>
  );
};

export default VesselPage;
