import * as React from 'react';
import { ReactNode } from 'react';
import styled from 'styled-components';

const DialogActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-top: 20px;
  button:disabled {
    cursor: not-allowed;
    }
`;

type Props = Readonly<{
  'data-test-id'?: string;
  children: ReactNode;
}>;

export function DialogActions({
  'data-test-id': dataTestId,
  children,
}: Props): JSX.Element {
  return (
    <DialogActionsWrapper data-test-id={dataTestId}>
      {children}
    </DialogActionsWrapper>
  );
}
