/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { mdiMagnify } from '@mdi/js';
import styled from 'styled-components';
import Icon from '../Icon/Icon';

const onKeyDown = (search, onChange) => evt => {
  if (evt.keyCode === 13) {
    // ENTER
    search();
  } else if (evt.keyCode === 27) {
    // ESC
    onChange('');
  }
};

const SearchContainer = styled.div`
  display: flex;
  height: 47px;
  justify-self: center;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 30px;
  flex: 1;

  @media (min-width: ${props => props.theme.breakpoints.pxPhone}) {
    margin-top: 10px;
  }
`;

const SearchInput = styled.input`
  border-radius: 10px 0px 0px 10px;
  outline: none;
  border: 0;
  padding: 0px 24px;
  font-size: 18px;
  flex: 1 0 1em;
  height: 47px;
  border: 1px solid ${props => props.theme.marineBlue};
`;

const SearchButton = styled.div`
  background-color: ${props => props.theme.marineBlue};
  text-align: center;
  border-radius: 0px 10px 10px 0px;
  width: 45px;
  height: 45px;
  border: 2px solid ${props => props.theme.marineBlue};
`;

const SearchField = ({ onChange, searchText, search }) => (
  <SearchContainer>
    <SearchInput
      name="search"
      type="text"
      placeholder="Vessel name or imo number"
      onChange={e => onChange(e.target.value)}
      onKeyDown={onKeyDown(() => search(searchText), onChange)}
      value={searchText}
    />
    <SearchButton type="submit" onClick={() => search(searchText)}>
      <Icon path={mdiMagnify} size="40px" />
    </SearchButton>
  </SearchContainer>
);

SearchField.propTypes = {
  onChange: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  search: PropTypes.func.isRequired,
};
export default SearchField;
