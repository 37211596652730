import { baseTheme } from '@instech/components';

export const theme = {
  ...baseTheme,
  breakpoints: {
    tablet: 1024,
    phone: 768,
    pxTablet: '1024px',
    pxPhone: '768px',
  },
  header: {
    height: '100px',
    scrolledHeight: '50px'
  }
};

export type Theme = typeof theme;

export const isPhone = (width: number) => width < theme.breakpoints.phone;
export const isTablet = (width: number) =>
  width >= theme.breakpoints.phone && width <= theme.breakpoints.tablet;
export const isDesktop = (width: number) => width > theme.breakpoints.tablet;
