/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import MdiIcon from '@mdi/react';
import styled from 'styled-components';

const IconContainer = styled(MdiIcon)`
  color: $text-light;
  display: flex;
`;

const Icon = ({ path, size, ...props }) => (
  <IconContainer path={path} {...props} color="#ffffff" size={size} />
);

Icon.propTypes = {
  path: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired
};

export default Icon;
