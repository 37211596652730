import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import {
  BoxButton,
  Divider,
  Loader
} from '@instech/components';
import { FloppyDisk } from '@instech/icons';
import { useReportId } from '@/hooks/useReportId';
import { Pane } from '../../Pane';
import { RichTextEditor } from '../RichTextEditor/RichTextEditor';
import { useFeatureFlag } from '../../../providers/featureFlagProvider';
import { useMarkdownTransformer } from '../RichTextEditor/hooks/useMarkdownTransformer';
import { transformMarkdown } from '../RichTextEditor/utils/markdown';

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px 20px;
  margin-top: -10px;
  & button {
    align-self: flex-end;
  }
`;

export interface RichText {
  text: string | null;
  images: string[];
}

interface RichTextFormProps {
  data?: RichText;
  onSubmit: (richText: RichText) => void;
  headerText: string;
  margin?: string;
}
export const RichTextForm = ({ data, onSubmit, headerText, margin }: RichTextFormProps) => {
  const reportId = useReportId();
  const [deletedImages, setDeletedImages] = React.useState<string[]>([]);
  const imageLoaderEnabled = useFeatureFlag('addImageLoader');
  const initialImages = useMemo(() => data?.images ?? [], [data?.images]);
  const initialMarkdown = useMarkdownTransformer(data?.text ?? '', initialImages, reportId);

  if (initialMarkdown === null && data?.text !== null) return <Loader />;

  const submit = async (richText: RichText) => {
    const result = await transformMarkdown(richText, deletedImages, reportId);
    const payload = result ?? { text: null, images: [] };
    onSubmit(payload);
  };

  const removeImage = (imageId: string) => setDeletedImages(prev => [...prev, imageId]);

  return (
    <Pane title={headerText} padding="0px" margin={margin}>
      <Formik
        initialValues={{ richText: { text: initialMarkdown ?? null, images: data?.images ?? [] } }}
        onSubmit={values => submit(values.richText)}
        enableReinitialize
      >
        {({ handleSubmit, dirty }) => (
          <Form>
            <RichTextEditor
              name="richText"
              withImageLoader={imageLoaderEnabled}
              initialState={initialMarkdown ?? ''}
              onImageRemoved={removeImage}
            />
            <Footer>
              <Divider />
              <BoxButton
                  startIcon={<FloppyDisk />}
                  onClick={handleSubmit}
                  disabled={!dirty}
                >
                Save
              </BoxButton>
            </Footer>
          </Form>
        )}
      </Formik>
    </Pane>
  );
};
