import { ACCEPTABLE_IMAGE_TYPES } from '@/utils/image';
import React, {
  useEffect, useRef, useState
} from 'react';
import styled from 'styled-components';
import { BoxButton } from '@instech/components';
import { LexicalEditor } from 'lexical';
import { FileInput } from '../../ui/FileInput';
import { TextInput } from '../../ui/TextInput';
import { DialogActions } from '../../ui/Dialog';
import { INSERT_IMAGE_COMMAND } from '../../utils/commands';

const ErrorMessage = styled.span`
  color: ${props => props.theme.warning};
  height: 15px;
  display: flex;
`;

interface InertImageDialogPayload {
  src: string;
  altText: string;
  fileName: string;
}
const InsertImageUploadedDialogBody = ({ onClick, }: {
  onClick: (payload: InertImageDialogPayload) => void;
}) => {
  const [src, setSrc] = useState('');
  const [altText, setAltText] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileType, setFileType] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const isValidFileType = fileType !== null ? ACCEPTABLE_IMAGE_TYPES.includes(fileType) : true;
    if (altText.length > 60) {
      setError('Alt text must be less than 60 characters.');
    }
    if (!isValidFileType) {
      setError('Invalid file type. Please upload a .jpg, .jpeg, .png, or .gif file.');
    }
    if (altText.length <= 60 && error !== null && isValidFileType) {
      setError(null);
    }
  }, [altText, error, fileType]);

  const isDisabled = src === '' || error !== null || !altText.length;

  const onConfirm = () => {
    onClick({ altText, src, fileName });
  };

  const loadImage = (files: FileList | null) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        setSrc(reader.result);
      }
      return '';
    };
    if (files !== null) {
      const file = files[0];

      setFileType(file.type);
      setFileName(file.name);
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <FileInput
        label="Image Upload"
        onChange={loadImage}
        accept="image/*"
        data-test-id="image-modal-file-upload"
      />
      <TextInput
        label="Image Text"
        placeholder="Mandatory descriptive image text"
        onChange={setAltText}
        value={altText}
        data-test-id="image-modal-alt-text-input"
      />
      <ErrorMessage>{error ?? ''}</ErrorMessage>
      <DialogActions>
        <BoxButton
          data-test-id="image-modal-file-upload-btn"
          disabled={isDisabled}
          onClick={onConfirm}>
          Confirm
        </BoxButton>
      </DialogActions>
    </>
  );
};

interface InsertImageDialogProps {
  activeEditor: LexicalEditor;
  onClose: () => void;
}
export const InsertImageDialog = ({ activeEditor, onClose }: InsertImageDialogProps) => {
  const hasModifier = useRef(false);

  useEffect(() => {
    hasModifier.current = false;
    const handler = (e: KeyboardEvent) => {
      hasModifier.current = e.altKey;
    };
    document.addEventListener('keydown', handler);
    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, [activeEditor]);

  const onClick = ({ src, altText, fileName }: InertImageDialogPayload) => {
    const payload = {
      src,
      altText,
      fileName,
    };
    activeEditor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);
    onClose();
  };

  return (
    <InsertImageUploadedDialogBody onClick={onClick} />
  );
};
