import React, { ReactElement } from 'react';
import { Position } from '@/types';
import { useReport } from '@/services/EmergencyReportService';
import { MapContainer } from './MapContainer';

interface Props {
  children: (position: Position) => ReactElement;
}
const PositionLoader = ({ children }: Props) => {
  const { position } = useReport();
  if (!position) return null;
  return children(position);
};

export const WrappedMap = () => (
  <PositionLoader>
    {position => <MapContainer position={position} />}
  </PositionLoader>
);
