import React, {
  createContext, useContext, ReactNode
} from 'react';
import useSWR from 'swr';
import { useAccount } from '@/providers/authenticationProvider';
import { getJsonAsync } from '@/services';

const featureFlagRoute = '/configuration/featureFlags';

type FeatureFlagContextProps = {
  [key: string]: boolean | undefined;
};

const FeatureFlagContext = createContext<FeatureFlagContextProps>({});

type FeatureFlagProviderProps = {
  children: ReactNode;
};

export const FeatureFlagProvider = ({ children }: FeatureFlagProviderProps) => {
  const { isAuthenticated } = useAccount();
  const { data } = useSWR(isAuthenticated ? featureFlagRoute : null, getJsonAsync);

  const value: FeatureFlagContextProps = data || {};
  return (
    <FeatureFlagContext.Provider value={value}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlagsContext = () => useContext(FeatureFlagContext);

export const useFeatureFlag = (key: string) => {
  const ctx = useFeatureFlagsContext();
  return !!ctx[key];
};
