import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useReport } from '@/services/EmergencyReportService';
import { PageLoader } from '@instech/components';
import { RichText, RichTextForm } from '../shared/Form/RichTextForm/RichTextForm';
import { PageNavigator } from '../shared/PageNavigator';
import VesselInformation from '../components/vessel/VesselDetail';

const CommentsArea = styled.div`
  min-height: calc(100vh - 60px);
  background-color: ${props => props.theme.whiteBlue};
  background-size: cover;
  width: 100%;
  margin: 0 auto;
`;

const Content = styled.div`
  min-height: calc(100vh - 60px);
  background-color: ${props => props.theme.white};
  background-size: cover;
  padding-top: 10px 0px 0px 0px;
  margin: 0 auto;
  max-width: 1200px;
`;

const CoverPane = styled.div`
  background: ${props => props.theme.whiteBlue};
  margin: 10px;
`;

export const CommentsPage = () => {
  const { report, selectComment } = useReport();
  const [shouldNavigate, setShouldNavigate] = React.useState(false);
  const history = useHistory();

  useEffect(() => {
    if (shouldNavigate && report) {
      history.push(`/${report.id}/summary`);
    }
  }, [shouldNavigate, report, history]);

  const onSaveComments = async (newData: RichText) => {
    await selectComment({ ...newData, text: newData.text ?? '' });
    setShouldNavigate(true);
  };

  if (!report) return <PageLoader />;

  return (
    <CommentsArea>
      <Content>
        <PageNavigator
          section={report.vessel.imoNumber}
          title={report.vessel.vesselName}
          toPagePrevious={`/${report.id}/map`}
          toPageNext={`/${report.id}/summary`}
        />
        <VesselInformation
          key="1"
          vessel={report.vessel}
         />

        <CoverPane>
          <RichTextForm
            data={report.comment}
            headerText="Inital comments - No personal data except name, phone and email"
            onSubmit={onSaveComments}
          />
        </CoverPane>
      </Content>
    </CommentsArea>
  );
};

export default CommentsPage;
