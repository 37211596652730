import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { usePosition } from '@/services/PositionService';
import { Position, Vessel } from '@/types';
import { BoxButton } from '@instech/components';
import { useModalContext } from '../../modal/ModalContext';
import { MapContainer } from './MapContainer';

const MapArea = styled.div`
  background-color: ${props => props.theme.whiteBlue};
  background-size: cover;
  margin: 0 auto;
  height: 75vh;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 3rem;
  margin: 0 auto;
  background: none;
  border: none;
  z-index: 10;
`;

interface BaseProps {
  imoNumber: Vessel['imoNumber'];
}

interface PositionLoaderProps extends BaseProps {
  children: (position: Position) => ReactElement;
}
const PositionLoader = ({ imoNumber, children }: PositionLoaderProps) => {
  const { data } = usePosition(imoNumber);
  if (!data) return null;
  return children(data);
};

export const WrappedMapTool = ({ imoNumber }: BaseProps) => {
  const modal = useModalContext();
  return (
    <MapArea>
      <ButtonWrapper>
        <BoxButton onClick={modal.close}>Close</BoxButton>
      </ButtonWrapper>
      <PositionLoader imoNumber={imoNumber}>
        {position => <MapContainer position={position} />}
      </PositionLoader>
    </MapArea>
  );
};

export const openWrappedMapTool = (imoNumber: Vessel['imoNumber']) => ({
  component: WrappedMapTool,
  options: {
    title: 'Vessel position',
    size: 'large',
  },
  args: { imoNumber },
});
